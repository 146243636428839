import PersonIcon from '@material-ui/icons/Person';
import {
  ChallengeOverviewCreate,
  ChallengeOverviewEdit,
  ChallengeOverviewView,
} from './views';

export default {
  create: ChallengeOverviewCreate,
  show: ChallengeOverviewView,
  edit: ChallengeOverviewEdit,
  icon: PersonIcon,
  name: 'section',
};
