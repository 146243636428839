import React, { useEffect, useState } from 'react';
import {
  List,
  Filter,
  TextInput,
  DateField,
  TextField,
  FunctionField,
  SelectInput,
  BooleanInput,
} from 'react-admin';
import Typography from '@material-ui/core/Typography';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import BlurOnIcon from '@material-ui/icons/BlurOn';
import BlurOffIcon from '@material-ui/icons/BlurOff';
import LabelIcon from '@material-ui/icons/Label';
import LabelOffIcon from '@material-ui/icons/LabelOff';

import { getChallenge } from 'services/challenge';
import Datagrid from 'components/Datagrid';
import { RatingField } from 'components/Rating/RatingField';
import { customExporter } from 'components/customExporter';
import Breadcrumb from 'components/Breadcrumb';
import ProgressBar from 'components/ProgressBar';

import { useChallengeProgressStyles } from './useChallengeProgressStyles';

const ratingChoices = [1, 2, 3, 4, 5].map(choice => ({
  id: choice,
  name: String(choice),
}));

interface Progress {
  id: string;
  challengeName: string;
  firstName: string;
  lastName: string;
  numOfProgress: string;
  numOfWeeks: string;
  completedWeeks: number[];
  notes: string;
  rating: string;
  lastSubmitted: string;
  submissions: number;
  consentGiven: boolean;
  blurImages: boolean;
  tagOnSocialNetworks: boolean;
}

const CompleteWeeksField = (props: any) => (
  <FunctionField
    {...props}
    render={(record: Progress) => (
      <Typography>{record.completedWeeks?.join(' / ')}</Typography>
    )}
  />
);

const SubmissionsField = (props: any) => (
  <FunctionField
    {...props}
    render={(record: Progress) => (
      <>
        <ProgressBar progress={Number(record.submissions)} />
        <span>{`${record.numOfProgress}/${record.numOfWeeks}`}</span>
      </>
    )}
  />
);

const ConsentField = (props: any) => {
  const classes = useChallengeProgressStyles();
  return (
    <FunctionField
      {...props}
      render={(record: Progress) => (
        <div className={classes.consentFlags}>
          {record.consentGiven ? (
            <>
              <CheckBoxIcon />
              {record.blurImages ? <BlurOnIcon /> : <BlurOffIcon />}
              {record.tagOnSocialNetworks ? <LabelIcon /> : <LabelOffIcon />}
            </>
          ) : (
            <CheckBoxOutlineBlankIcon />
          )}
        </div>
      )}
    />
  );
};

const UserChallengeFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="First Name" source="firstName" />
    <TextInput label="Last Name" source="lastName" />
    <SelectInput
      label="Rating"
      source="rating"
      choices={ratingChoices}
      allowEmpty={false}
    />
    <TextInput
      label="Submission less than"
      source="submissions||$lt"
      format={(v: string) => Number(v) * 100}
      parse={(v: string) => Number(v) / 100}
      type="number"
    />
    <TextInput
      label="Submission greater than"
      source="submissions||$gt"
      format={(v: string) => Number(v) * 100}
      parse={(v: string) => Number(v) / 100}
      type="number"
    />
    <BooleanInput
      label="Consent Given"
      source="consentGiven"
      defaultValue={true}
    />
  </Filter>
);

const exporter = (progresses: Progress[]) => {
  const dataForExport = progresses.map(progress => {
    const {
      challengeName,
      firstName,
      lastName,
      numOfProgress,
      numOfWeeks,
      completedWeeks,
      notes,
      rating,
      lastSubmitted,
      blurImages,
      consentGiven,
      tagOnSocialNetworks,
    } = progress;

    return {
      Challenge: `${challengeName}`,
      Name: `${firstName} ${lastName}`,
      'Submitted Weeks': `${completedWeeks?.join(' / ')}`,
      Submissions: `${numOfProgress}/${numOfWeeks}`,
      Notes: `${notes}`,
      Rating: `${rating}`,
      'Consent given': consentGiven ? 'Yes' : 'No',
      'Blur images': blurImages ? 'Yes' : 'No',
      'Tag on social media': tagOnSocialNetworks ? 'Yes' : 'No',
      'Updated At': lastSubmitted,
    };
  });

  customExporter(dataForExport, 'Progress');
};

export const ChallengeProgressList = (props: any) => {
  const [challengeName, setChallengeName] = useState('Challenge');

  const classes = useChallengeProgressStyles();

  const {
    match: {
      params: { challengeId },
    },
  } = props;

  useEffect(() => {
    const loadChallenge = async () => {
      try {
        const { data } = await getChallenge(challengeId);
        setChallengeName(data?.name || 'Challenge');
      } catch (error) {
        console.error(error);
      }
    };

    loadChallenge();
  }, []);

  return (
    <>
      <Breadcrumb
        routes={[
          { to: '/#/challenge', text: 'Challenges' },
          { to: `/#/challenge/${challengeId}/show`, text: challengeName },
          { text: 'Progress' },
        ]}
      />
      <List
        {...props}
        title="Progress"
        syncWithLocation
        exporter={exporter}
        bulkActionButtons={false}
        resource="user-challenge-report"
        basePath="/user-challenge-report"
        filters={<UserChallengeFilter />}
        filter={{ 'challengeId||$eq': challengeId }}
      >
        <Datagrid rowClick="edit">
          <TextField source="firstName" label="First Name" />
          <TextField source="lastName" label="Last Name" />
          <CompleteWeeksField label="Submitted Weeks" />
          <TextField source="notes" label="Notes" />
          <RatingField source="rating" />
          <SubmissionsField
            source="submissions"
            label="Submissions"
            className={classes.submissionContent}
          />
          <ConsentField label="Consent" />
          <DateField source="lastSubmitted" label="Last Submitted" />
        </Datagrid>
      </List>
    </>
  );
};
