import { makeStyles } from '@material-ui/core/styles';

export const useProgressStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    position: 'relative',
    backgroundColor: theme.palette.grey[400],
    height: 10,
    width: 100,
    borderRadius: theme.shape.borderRadius,
    overflow: 'hidden',
  },
  progress: {
    height: '100%',
  },
  text: {
    position: 'absolute',
    right: 0,
    left: 0,
    bottom: 0,
    top: 0,
    textAlign: 'center',
    fontSize: 9,
    fontWeight: 'bold',
    color: theme.palette.common.black,
  },
}));
