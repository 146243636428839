interface Ierrors {
  firstName?: string;
  lastName?: string;
  username?: string;
  email?: string;
  password?: string;
  heightInches?: string;
  dateOfBirth?: string;
}

export const validateUserCreation = (values: any) => {
  const errors: Ierrors = {};
  if (!values.firstName) {
    errors.firstName = 'The firstName is required';
  }
  if (!values.lastName) {
    errors.lastName = 'The lastName is required';
  }
  if (!values.email) {
    errors.email = 'The email is required';
  }
  if (!values.dateOfBirth) {
    errors.dateOfBirth = 'The date of birth is required';
  }

  if ((values.heightInches < 36 || values.heightInches > 96))
  {
    errors.heightInches = 'The height must be between 3 and 8 feet';
  }
  
  return errors;
};
