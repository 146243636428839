import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    aspectRatio: '16/9',
    width: '100%',
    maxWidth: '100%',
    borderRadius: '12px',
    backgroundColor: '#f1f1f1',
    overflow: 'hidden',
    '@media (min-width: 480px)': {
      width: '50%',
      maxWidth: '500px',
    },
  },
  frame: {
    width: '100%',
    height: '100%',
    border: 'none',
  },
  errorText: {
    textAlign: 'center',
    margin: '8px',
  },
});
