import React, { Fragment, useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ImageLogo from 'assets/logo.webp';
import { makeStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
// import CachedIcon from '@material-ui/icons/Cached';
import { changePassword, verifyToken } from 'services/auth';

const useStyles = makeStyles({
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginTop: '20px',
  },
  logo: {
    width: 'fit-content',
    marginBottom: '30px',
  },
  cardLogin: {
    maxWidth: '400px',
    width: '20vw',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  input: {
    marginBottom: '10px',
  },
  submitButton: {
    marginLeft: 'auto',
    width: 'fit-content',
    fontWeight: 'bold',
  },
  recoveryButton: {
    width: 'fit-content',
    fontWeight: 'bold',
  },
  successText: {
    color: 'green',
    marginBottom: '10px',
  },
  rotate: {
    animation: 'rotating 2s linear infinite',
  },
  nonRotate: {
    animation: 'none',
  },
  errorText: {
    color: 'red',
    marginTop: '10px',
  },
});

interface IConfirm {
  match: {
    params: {
      token: string;
    };
  };
  children?: React.ReactNode;
}

const getMessage = (error: any) => {
  const message =
    error.response && error.response.data && error.response.data.message;
  if (!message) {
    return error.response.statusText;
  }
  return typeof message !== 'string'
    ? error.response.data.message[0]
    : error.response.data.message;
};

export const ConfirmRecovery: React.FC<IConfirm> = props => {
  const [passwordChanged, setPasswordChanged] = useState(false);
  // const [sending, setSending] = useState(false);
  const classes = useStyles();
  const [errorMessage, setErrorMessage] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    const token = props.match.params.token;
    verifyToken({ token }).then(response => {
      console.log(JSON.stringify(response));
    });
  }, []);

  const confirmChange = () => {
    changePassword({ password, token: props.match.params.token })
      .then(_response => {
        setPasswordChanged(true);
      })
      .catch(response => {
        setErrorMessage(getMessage(response));
      });
  };

  const onChangePassword = (event: React.ChangeEvent<HTMLInputElement>) =>
    setPassword(event.target.value);

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
    >
      <Box
        borderRadius={10}
        boxShadow={3}
        p={4}
        px={6}
        className={classes.cardLogin}
      >
        <img src={ImageLogo} className={classes.logo} />
        {passwordChanged ? (
          <Fragment>
            <Typography className={classes.successText}>
              Password Changed!
            </Typography>
            <Button
              variant="contained"
              color="primary"
              className={classes.recoveryButton}
              onClick={confirmChange}
              href="/#/login"
            >
              Go to login
            </Button>
          </Fragment>
        ) : (
          <Fragment>
            <Typography variant="h5">Add the new password</Typography>
            <form className={classes.form}>
              <Fragment>
                <TextField
                  className={classes.input}
                  id="standard-basic"
                  label="Password"
                  type="password"
                  variant="outlined"
                  value={password}
                  onChange={onChangePassword}
                />
                <Box display="flex" justifyContent="flex-end">
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.recoveryButton}
                    startIcon={<CheckIcon />}
                    onClick={confirmChange}
                  >
                    Confirm
                  </Button>
                </Box>
                {errorMessage && (
                  <Box>
                    <Typography className={classes.errorText}>
                      {errorMessage}
                    </Typography>
                  </Box>
                )}
              </Fragment>
            </form>
          </Fragment>
        )}
      </Box>
    </Box>
  );
};

export default ConfirmRecovery;
