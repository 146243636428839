import React from 'react';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

import { useBreadcrumbStyles } from './styles';

interface BreadcrumbItem {
  text: string;
  to?: string;
}

interface BreadcrumbProps {
  routes: BreadcrumbItem[];
}

export const Breadcrumb: React.FC<BreadcrumbProps> = ({ routes }) => {
  const classes = useBreadcrumbStyles();

  return (
    <div className={classes.container}>
      {routes.map((route, index) => (
        <>
          <Typography key={`route_${route.text}_${index.toString()}`}>
            {route.to ? <Link href={route.to}>{route.text}</Link> : route.text}
          </Typography>
          {index < routes.length - 1 && <Typography>/</Typography>}
        </>
      ))}
    </div>
  );
};

export default Breadcrumb;
