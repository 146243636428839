import { useState } from 'react';
import { useNotify } from 'react-admin';
import { Uppy, UppyFile } from '@uppy/core';
import { DragDrop, useUppy } from '@uppy/react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/RemoveCircle';
import Box from '@material-ui/core/Box';

import SimpleForm from 'components/SimpleForm';

import '@uppy/core/dist/style.css';
import '@uppy/drag-drop/dist/style.css';
import '@uppy/progress-bar/dist/style.css';

interface Props {
  open: boolean;
  title: string;
  confirmText?: string;
  cancelText?: string;
  label?: string;
  onUpload: (file: Blob | File) => Promise<void>;
  onConfirm: () => void;
  onClose: () => void;
  onError?: (error: any) => void;
}

export const UploadDialog = ({
  open,
  title,
  confirmText = 'Confirm',
  cancelText = 'Cancel',
  label = 'File',
  onClose,
  onError,
  onConfirm,
  onUpload,
}: Props): JSX.Element => {
  const [uploading, setUploading] = useState(false);
  const [files, setFiles] = useState<UppyFile[]>([]);
  const notify = useNotify();

  const uppy = useUppy(() => {
    return new Uppy({
      restrictions: {
        allowedFileTypes: ['.csv'],
        maxNumberOfFiles: 1,
        minNumberOfFiles: 1,
      },
      autoProceed: false,
      allowMultipleUploads: false,
      meta: { type: 'csv' },
    })
      .on('file-added', () => {
        setFiles(uppy.getFiles());
      })
      .on('file-removed', () => {
        setFiles(uppy.getFiles());
      })
      .on('upload', async ({ fileIDs }) => {
        const id = fileIDs[0];
        const file = uppy.getFile(id);

        try {
          setUploading(true);
          await onUpload(file.data);
          onConfirm();
        } catch (error) {
          onError?.(error);
        } finally {
          uppy.reset();
          setUploading(false);
        }
      });
  });

  const handleConfirm = async () => {
    try {
      await uppy.upload();
    } catch (error) {
      notify(
        error?.message ||
          'Failed to upload exercises, Please check the file integrity and try again.',
        'error',
      );
    }
  };

  const handleClose = () => {
    uppy.cancelAll();
    uppy.reset();
    onClose();
  };

  return (
    <SimpleForm hideToolbar mutationMode="pessimistic" basePath="" resource="">
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DragDrop
            uppy={uppy}
            width="100%"
            height="100%"
            note={label}
            allowMultipleFiles={false}
            locale={{
              strings: {
                dropHereOr: 'Drop a csv file here or %{browse}',
                browse: 'browse',
              },
            }}
          />
          <Box
            display="flex"
            flexDirection="column"
            gridGap="8px"
            paddingY="8px"
          >
            {files.map(file => (
              <Box key={file.id} display="flex" alignItems="center">
                <IconButton
                  size="small"
                  color="secondary"
                  onClick={() => uppy.removeFile(file.id)}
                >
                  <CancelIcon />
                </IconButton>
                <Typography>{file.name}</Typography>
              </Box>
            ))}
          </Box>
        </DialogContent>
        <DialogActions>
          <Box display="flex" alignItems="center" paddingX="8px" gridGap="16px">
            <Button
              onClick={handleConfirm}
              color="primary"
              variant="text"
              disabled={uploading}
              autoFocus
            >
              {confirmText}
            </Button>
            <Button onClick={handleClose} color="primary" variant="text">
              {cancelText}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </SimpleForm>
  );
};

export default UploadDialog;
