import PersonIcon from '@material-ui/icons/Person';
import {
  SubscriptionsList,
  SubscriptionsCreate,
  SubscriptionsView,
} from './views';

export default {
  create: SubscriptionsCreate,
  edit: SubscriptionsCreate,
  show: SubscriptionsView,
  list: SubscriptionsList,
  icon: PersonIcon,
  name: 'subscription',
  options: { label: 'Subscriptions' },
};
