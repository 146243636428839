import { axiosInstance } from 'services/http';

interface CommentVisibleOptions {
  id: string;
  moderatorVisible: boolean;
}

export const toggleCommentVisible = async (
  options: CommentVisibleOptions,
): Promise<void> => {
  const { id, moderatorVisible } = options;
  return axiosInstance.patch(`challenge-comment/${id}`, { moderatorVisible });
};

interface CommentBlockOptions {
  id: string;
  moderatorBlock: boolean;
}

export const toggleCommentBlock = async (
  options: CommentBlockOptions,
): Promise<void> => {
  const { id, moderatorBlock } = options;
  return axiosInstance.patch(`challenge-comment/${id}`, { moderatorBlock });
};
