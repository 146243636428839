import { UserIdentity } from 'react-admin';
import { axiosInstance } from 'services/http';
export interface AuthCredentialsInterface {
  username: string;
  password: string;
  assertRoles?: string[];
}

interface loginResponse {
  accessToken: string;
}

interface meResponse {
  userId: string;
  firstName: string;
  lastName: string;
  username: string;
}

interface errorResponse {
  response: {
    status: number;
    config: {
      url: string;
    };
  };
}

export const login = ({
  username,
  password,
}: AuthCredentialsInterface): Promise<void> => {
  return axiosInstance
    .post<loginResponse>('auth/signin', { username, password })
    .then(response => {
      localStorage.setItem('accessToken', response.data.accessToken);
    });
};

export const getIdentity = (): Promise<UserIdentity> => {
  return axiosInstance.get<meResponse>('me').then(response => {
    return {
      id: response.data.userId,
      fullName: `${response.data.firstName} ${response.data.lastName}`,
      firstName: response.data.firstName,
      lastName: response.data.lastName,
      username: response.data.username,
    } as UserIdentity;
  });
};

export default {
  login,
  logout: (): Promise<void> => {
    localStorage.removeItem('accessToken');
    return Promise.resolve();
  },
  checkError: (response: errorResponse): Promise<void> => {
    const {
      response: { status },
    } = response;
    if (status === 401 || status === 403) {
      if (response.response.config.url != 'notification') {
        localStorage.removeItem('accessToken');
        return Promise.reject();
      }
    }
    return Promise.resolve();
  },
  checkAuth: (): Promise<void> => {
    return localStorage.getItem('accessToken')
      ? Promise.resolve()
      : Promise.reject();
  },
  getPermissions: (): Promise<void> => Promise.resolve(),
  getIdentity,
};
