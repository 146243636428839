import PersonIcon from '@material-ui/icons/Person';
import {
  NotificationsList,
  NotificationView,
  NotificationsCreate,
} from './views';

export default {
  list: NotificationsList,
  show: NotificationView,
  create: NotificationsCreate,
  icon: PersonIcon,
  name: 'notification',
  options: { label: 'Notifications' },
};
