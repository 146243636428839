import * as React from 'react';
import { TextField, DateField, DeleteButton } from 'react-admin';
import SimpleForm from '../../../components/SimpleForm';
import Typography from '@material-ui/core/Typography';
import { useDeviceStyles } from '../views/useDeviceStyles';
import RedirectButton from 'components/redirect-button';
import BottomToolbar from '../../../components/Toolbar';

const CustomDelete = (props: any) => {
  const classes = useDeviceStyles();

  return (
    <React.Fragment>
      <DeleteButton
        {...props}
        confirmTitle={`Are you sure you want to REMOVE this Device?`}
        confirmContent={`If you remove this device, it will no longer list in the User Devices. This device can be re-added if the User hasn't reached the limit of allowed devices on the account.`}
        mutationMode={'optimistic'}
        className={classes.deleteButton}
      />
    </React.Fragment>
  );
};

export const DeviceForm = (props: any) => {
  const classes = useDeviceStyles();

  const FullName = (props: any) => {
    const { record } = props;

    return (
      <React.Fragment>
        <TextField
          record={{
            id: 0,
            fullName: `${record.user?.firstName} ${record.user?.lastName}`,
          }}
          source="fullName"
          className={classes.labelField}
        />
      </React.Fragment>
    );
  };

  const CustomToolBar = () => {
    return (
      <BottomToolbar
        hasSaveButton={false}
        backButton={
          <RedirectButton
            label="Go Back"
            redirectTo={{
              pathname: `/device`,
            }}
          />
        }
      />
    );
  };

  return (
    <React.Fragment>
      <SimpleForm {...props} hideToolbar={true}>
        <CustomDelete />
        <Typography className={classes.label} component="div">
          User
        </Typography>
        <FullName label="User" />
        <TextField source="deviceType" label="Device Type" />
        <TextField source="modelName" label="Model Name" />
        <DateField source="createdAt" label="First Sign-in" />
      </SimpleForm>
      <CustomToolBar />
    </React.Fragment>
  );
};
