import {
  Edit,
  TextField,
  BooleanInput,
  FunctionField,
  DateField,
} from 'react-admin';
import SimpleForm from 'components/SimpleForm';
import Typography from '@material-ui/core/Typography';
import { useStyles } from './useStyles';
import Breadcrumb from 'components/Breadcrumb';

export const UserSubscriptionsEdit = (props: any) => {
  const classes = useStyles();

  return (
    <Edit {...props} mutationMode={'optimistic'}>
      <SimpleForm>
        <Breadcrumb routes={[{ text: 'User Subscription' }]} />
        <FunctionField
          label="User"
          render={(record: any) =>
            `${record?.user?.firstName} ${record?.user?.lastName}`
          }
        />
        <TextField
          source="user.email"
          label="E-mail"
          formClassName={classes.subscriptionField}
        />
        <TextField
          source="subscription.name"
          label="Subscription"
          formClassName={classes.subscriptionField}
        />
        <DateField
          source="expirationDate"
          label="Expiration Date"
          options={{
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            timeZone: 'UTC',
          }}
          locales="en-US"
        />
        <BooleanInput
          source="active"
          label="Subscription Enabled"
          className={classes.subscriptionField}
        />
        <Typography className={classes.subscriptionMessage}>
          In addition to deactivating the subscription, it is important to{' '}
          <strong>cancel it through the payment provider</strong> to stop future
          charges.
        </Typography>
      </SimpleForm>
    </Edit>
  );
};
