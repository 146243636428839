import React from 'react';
import { Button } from 'react-admin';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

export interface RowError {
  row: number | undefined;
  error: string;
}

interface Props {
  open: boolean;
  onClose: () => void;
  errors: RowError[];
}

export const UploadErrorDialog = ({
  open,
  onClose,
  errors,
}: Props): JSX.Element => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle id="alert-dialog-title">
        {`${errors.length} ${errors.length > 1 ? 'Errors' : 'Error'}`} prevented
        the file from importing. Please fix the{' '}
        {errors.length > 1 ? 'errors' : 'error'} below and try again.
      </DialogTitle>
      <DialogContent>
        <Box
          style={{
            padding: '10px 20px',
            border: '1px solid #e0e0e0',
            borderRadius: 8,
            backgroundColor: '#f2f2f2',
            maxHeight: 200,
            overflow: 'auto',
          }}
        >
          {errors.map(({ error, row }, index) => (
            <Typography key={`${row}_${index}`} style={{ marginBottom: 8 }}>
              {row && `Line ${row} - `}
              {error}
            </Typography>
          ))}
        </Box>
      </DialogContent>
      <DialogActions>
        <Box display="flex" alignItems="center" paddingX="8px" gridGap="16px">
          <Button
            onClick={onClose}
            color="primary"
            variant="text"
            label="Continue"
          />
        </Box>
      </DialogActions>
    </Dialog>
  );
};
