import * as React from 'react';
import { Edit, SaveButton } from 'react-admin';
import { validateChallengeCreation } from './challengeValidation';
import { useChallengeStyles } from './useChallengeStyles';
import { ChallengeForm } from '../components/challenges-edit-form';
import { useHistory } from 'react-router-dom';

const CustomSaveButton = (props: any) => {
  const history = useHistory();

  const handleSuccess = () => {
    history.goBack();
  };
  return <SaveButton {...props} onSuccess={handleSuccess} />;
};

export const ChallengeEdit = (props: any) => {
  const classes = useChallengeStyles();

  return (
    <Edit {...props} undoable={false} className={classes.createBox}>
      <ChallengeForm
        validate={validateChallengeCreation}
        customSaveButton={<CustomSaveButton />}
      />
    </Edit>
  );
};
