import PersonIcon from '@material-ui/icons/Person';
import {
  DeviceList,
  DeviceEdit,
} from './views';

export default {
  edit: DeviceEdit,
  list: DeviceList,
  icon: PersonIcon,
  name: 'device',
  options: { label: 'Devices' },
};
