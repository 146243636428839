import { FC } from 'react';
import { Create, Edit } from 'react-admin';
import { validateSubscriptionCreation } from './subscriptions-validation';
import { useSubscriptionsStyles } from './useSubscriptionsStyles';
import { SubscriptionsForm } from '../components/subscriptions-edit-form';
import Breadcrumb from 'components/Breadcrumb';

export const SubscriptionsCreate: FC<any> = props => {
  const { id } = props;
  const classes = useSubscriptionsStyles();

  const Container = id ? Edit : Create;

  return (
    <>
      <Breadcrumb
        routes={[
          { to: `/#/subscription`, text: 'Subscriptions' },
          { text: id ? 'Edit' : 'New' },
        ]}
      />
      <Container
        {...props}
        mutationMode="pessimistic"
        className={classes.createBox}
      >
        <SubscriptionsForm {...props} validate={validateSubscriptionCreation} />
      </Container>
    </>
  );
};
