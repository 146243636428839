import * as React from 'react';
import {
  List,
  Filter,
  TextInput,
  DateField,
  TextField,
  DeleteButton,
  SelectInput,
} from 'react-admin';

import Datagrid from 'components/Datagrid'; 
import { customExporter } from 'components/customExporter';

const DeviceFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="First Name" source="user.firstName" />
    <TextInput label="Last Name" source="user.lastName" />
    <TextInput label="Email" source="user.email" />
    <SelectInput
      label="Device Type"
      source="deviceType"
      choices={[
        { id: 'iOS', name: 'iOS' },
        { id: 'Android', name: 'Android' },
      ]}
    />
    <TextInput label="Model Name"  source="modelName" />
  </Filter>
);

const exporter = (devices:any) => {
  const dataForExport = devices.map((device:any) => {
    const {
      user,
      deviceType,
      modelName,
      createdAt,
    } = device;

    return {
      "Name": `${user.firstName} ${user.lastName}`,
      "Device Type": `${deviceType}`,
      "Model Name": `${modelName}`,
      "First Sign-in": createdAt,
    };
  });

  customExporter(dataForExport, "Devices");
};

export const DeviceList = (props: any) => {
  return (
    <List
      {...props}
      filters={<DeviceFilter />}
      hasCreate={false}
      hasEdit={false}
      bulkActionButtons={false}
      title="Devices"
      exporter={exporter}
    >
      <Datagrid rowClick="edit">
        <TextField source="user.firstName" label="First Name" />
        <TextField source="user.lastName" label="Last Name" />
        <TextField source="user.email" label="Email" />
        <TextField source="deviceType" label="Device Type" />
        <TextField source="modelName" label="Model Name" />
        <DateField source="createdAt" label="First Sign-in" />
        <DeleteButton
          confirmTitle={`Are you sure you want to REMOVE this Device?`}
          confirmContent={`If you remove this device, it will no longer list in the User Devices. This device can be re-added if the User hasn't reached the limit of allowed devices on the account.`}
          mutationMode={'optimistic'}/>
      </Datagrid>
    </List>
  );
};