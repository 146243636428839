import * as React from 'react';
import { Show, DateField } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import { useExerciseStyles } from './useExerciseStyles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Box from '@material-ui/core/Box';
import CheckIcon from '@material-ui/icons/Check';
import Block from '@material-ui/icons/Block';
import BottomToolbar from '../../../components/Toolbar';
import RedirectButton from 'components/redirect-button';

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const ShowLibraryExercise = (props: any) => {
  const { record } = props;

  const classes = useExerciseStyles();

  const rows = [
    { key: 'title', name: 'Title', details: record.title },
    {
      key: 'availableToBeSelected',
      name: 'Available to be Selected',
      details: record.availableToBeSelected ? <CheckIcon /> : <Block />,
    },
    { key: 'videoUrl', name: 'Video url', details: record.videoUrl },
    {
      key: 'comments',
      name: 'Default comments',
      details: record.comments,
    },
    {
      key: 'updatedAt',
      name: 'Last Update',
      details: (
        <DateField
          source="updatedAt"
          options={{
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            timeZone: 'US/Eastern',
          }}
          emptyText="-"
        />
      ),
    },
  ];

  const renderRows = () =>
    rows.map(row => (
      <StyledTableRow key={row.key}>
        <StyledTableCell component="th" scope="row">
          {row.name}
        </StyledTableCell>
        <StyledTableCell align="right">{row.details}</StyledTableCell>
      </StyledTableRow>
    ));

  return (
    <Box>
      <Box
        display="flex"
        flexDirection="column"
        m={3}
        className={classes.content}
      >
        <Box mb={3}>{record.name}</Box>
        <Box mb={3}>{record.description}</Box>
        <Box>
          <Table className={classes.table} aria-label="customized table">
            <TableBody>{renderRows()}</TableBody>
          </Table>
        </Box>
      </Box>
      <BottomToolbar
        hasSaveButton={false}
        backButton={
          <RedirectButton
            label="Go Back"
            redirectTo={{ pathname: `/exercise-library` }}
          />
        }
      />
    </Box>
  );
};

export const ExercisesLibraryView = (props: any) => {
  const classes = useExerciseStyles();

  return (
    <Show {...props} className={classes.createBox}>
      <ShowLibraryExercise {...props} />
    </Show>
  );
};
