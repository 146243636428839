import {
  Button,
  DateField,
  ImageField,
  TextInput,
  useRecordContext,
} from 'react-admin';
import Typography from '@material-ui/core/Typography';
import GetAppIcon from '@material-ui/icons/GetApp';
import Link from '@material-ui/core/Link';
import { ChallengeProgress, Week } from './challenge-progress-edit-form';

import { useChallengeProgressStyles } from '../views/useChallengeProgressStyles';

interface ChallengeWeekProps {
  week: Week;
  onImageOpen: (url: string) => void;
}

export const ChallengeWeek: React.FC<ChallengeWeekProps> = ({
  week,
  onImageOpen,
}) => {
  const record = useRecordContext<ChallengeProgress>();
  const classes = useChallengeProgressStyles();

  const isPreChallenge = week.weekNumber === 0;

  const { userChallengeProgresses } = record;

  const progressIndex = userChallengeProgresses?.findIndex(
    ({ challengeWeekId }) => challengeWeekId === week?.id,
  );
  const progress = userChallengeProgresses?.[progressIndex];

  if (!progress) {
    return (
      <div className={classes.weekContainer}>
        <h1>{isPreChallenge ? 'Pre-Challenge' : `Week ${week.weekNumber}`}</h1>
        <p>
          {isPreChallenge
            ? 'No Pre-Challenge information was submitted'
            : 'No results were submitted for this week.'}
        </p>
      </div>
    );
  }

  const { photos, updatedAt, createdAt } = progress;

  const ImageArrayField = () => {
    if (!photos) {
      return <></>;
    }

    return (
      <div>
        <div className={classes.photos}>
          {photos.map((item, ind) => {
            const { metaData, url } = item;

            let date = updatedAt || createdAt;
            let dateLabel = 'Upload date:';

            try {
              if (typeof metaData?.date === 'string') {
                if (!Number.isNaN(new Date(metaData.date).getTime())) {
                  date = metaData.date;
                  dateLabel = 'Photo taken on:';
                }
              }
            } catch (error) {
              date = updatedAt || createdAt;
              dateLabel = 'Upload date:';
            }

            return (
              <div key={ind} className={classes.photo}>
                <Button onClick={() => onImageOpen(url)}>
                  <ImageField key={url} source="url" record={{ id: 0, url }} />
                </Button>
                <div key={ind} className={classes.photoMetaData}>
                  <div key={ind} className={classes.photoMetaDataItem}>
                    <Typography
                      component="span"
                      className={classes.photoMetaDataKey}
                    >
                      {dateLabel}
                    </Typography>
                    <DateField
                      record={{ id: 0, date }}
                      source="date"
                      showTime={false}
                      className={classes.photoMetaDataValue}
                    />
                    <Link
                      href={url}
                      target="_blank"
                      className={classes.download}
                    >
                      <GetAppIcon />
                    </Link>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <div className={classes.weekContainer}>
      <h1>
        {week.weekNumber === 0 ? 'Pre-Challenge' : `Week ${week.weekNumber}`}
      </h1>
      <div className={classes.weekInfo}>
        <div>
          <Typography className={classes.label} component="div">
            Date of the first upload
          </Typography>
          <DateField
            record={{ id: 0, createdAt }}
            source="createdAt"
            label="Submitted"
            className={classes.weekInfoText}
          />
        </div>
        <div>
          <Typography className={classes.label} component="div">
            Date of the last update
          </Typography>
          <DateField
            record={{ id: 0, updatedAt }}
            source="updatedAt"
            label="Last Updated"
            className={classes.weekInfoText}
          />
        </div>
      </div>
      <div className={classes.weekInfo}>
        {!!progress?.startWeightLbs && (
          <div>
            <Typography className={classes.label}>Start Weight</Typography>
            <Typography className={classes.labelField}>
              {progress.startWeightLbs}
            </Typography>
          </div>
        )}
        {!isPreChallenge && !!progress?.endWeightLbs && (
          <div>
            <Typography className={classes.label}>End Weight</Typography>
            <Typography className={classes.labelField}>
              {progress.endWeightLbs}
            </Typography>
          </div>
        )}
      </div>
      <ImageArrayField />
      <TextInput
        source={`userChallengeProgresses[${progressIndex}].comments`}
        label="Participant Comments"
        multiline
        fullWidth
        rows={3}
        disabled
      />
    </div>
  );
};
