import { Edit } from 'react-admin';
import { useDeviceStyles } from './useDeviceStyles';
import { DeviceForm } from '../components/user-device-edit-form';

export const DeviceEdit = (props: any) => {
  const classes = useDeviceStyles();

  return (
    <Edit {...props} className={classes.createBox}>
      <DeviceForm />
    </Edit>
  );
};
