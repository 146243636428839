import * as React from 'react';
import { Create } from 'react-admin';
import { validateChallengeWeekCreation } from './challengeWeekValidation';
import { useChallengeWeekStyles } from './useChallengeWeekStyles';
import { ChallengeForm } from '../components/challenge-week-edit-form';

export const ChallengeWeekCreate = (props: any) => {
  const classes = useChallengeWeekStyles();

  return (
    <Create {...props} className={classes.createBox}>
      <ChallengeForm validate={validateChallengeWeekCreation} />
    </Create>
  );
};
