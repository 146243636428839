import React, { FC, useEffect } from 'react';
import {
  AutocompleteInput,
  ReferenceInput,
  SelectInput,
  TextInput,
  useDataProvider,
  useNotify,
} from 'react-admin';
import { useFormState, useForm } from 'react-final-form';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import SimpleForm from '../../../components/SimpleForm';
import { useExerciseStyles } from '../views/useExerciseStyles';
import DayOfWeekSelect from './day-of-week-select';
import VideoPreview from 'components/VideoPreview';

const ExerciseInput: FC<any> = props => {
  const classes = useExerciseStyles();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const { values } = useFormState();
  const { change } = useForm();

  const id = values?.exerciseLibraryId;
  const isEdit = !!props?.record?.id;

  useEffect(() => {
    const getExerciseDetails = async () => {
      if (!id) return;
      if (isEdit) return;

      try {
        const { data } = await dataProvider.getOne('exercise-library', { id });
        const { title, videoUrl, comments } = data;
        change('title', title);
        change('videoUrl', videoUrl);
        change('comments', comments);
      } catch (error) {
        notify('Failed to load exercise, please try again', 'error');
      }
    };

    getExerciseDetails();
  }, [id, isEdit]);

  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        gridGap="16px"
      >
        <Box display="flex" flex={1}>
          {isEdit ? (
            <TextInput
              source="title"
              type="string"
              label="Title"
              disabled={!!id}
              className={classes.autoCompleteFullWidth}
            />
          ) : (
            <ReferenceInput
              label="Title"
              source="exerciseLibraryId"
              reference="exercise-library"
              filterToQuery={title => (title ? { title } : {})}
              filter={{ 'availableToBeSelected||$eq': true }}
              sort={{ field: 'title', order: 'ASC' }}
              allowEmpty={false}
            >
              <AutocompleteInput
                optionText="title"
                optionValue="id"
                fullWidth
              />
            </ReferenceInput>
          )}
        </Box>
        <Box display="flex" flexDirection="row" alignItems="center">
          <Typography className={classes.libraryLinkText}>
            {!!id || !isEdit
              ? 'Linked to the Exercise Library'
              : 'Not linked to the Exercise Library'}
          </Typography>
        </Box>
      </Box>
      <TextInput
        source="videoUrl"
        type="string"
        fullWidth
        label="Video URL"
        disabled={!!id || !isEdit}
      />
      <VideoPreview source="videoUrl" />
    </>
  );
};

export const ExerciseForm: FC<any> = props => {
  const classes = useExerciseStyles();
  const urlParams = new URLSearchParams(props.location.search);
  const challengeId = urlParams.get('challengeId');
  const redirectTo = () =>
    challengeId
      ? `/challenge/${challengeId}/week/${props.record.challengeWeekId}/exercises`
      : '/challenge';

  return (
    <SimpleForm {...props} redirectOnSave={redirectTo}>
      <ExerciseInput />
      <TextInput
        source="comments"
        fullWidth
        multiline
        rows={3}
        InputLabelProps={{ shrink: true }}
      />
      <Box className={classes.divider} />
      <DayOfWeekSelect />
      <Box display="flex" flexDirection="row">
        <TextInput
          className={classes.marginRight}
          label="Weight"
          type="string"
          source="weight"
        />
        <TextInput label="SetCount" type="number" source="setCount" />
      </Box>
      <Box display="flex" flexDirection="row">
        <TextInput
          className={classes.marginRight}
          label="RepCount"
          type="number"
          source="repCount"
        />
        <TextInput label="Rest Time" type="string" source="restSeconds" />
      </Box>
      <SelectInput
        source="location"
        choices={[
          { id: 'gym', name: 'gym' },
          { id: 'home', name: 'home' },
        ]}
      />
    </SimpleForm>
  );
};
