import React from 'react';
import {
  List,
  EmailField,
  EditButton,
  Filter,
  TextInput,
  TextField,
  Title,
  DateField,
} from 'react-admin';
import Datagrid from 'components/Datagrid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Breadcrumb from 'components/Breadcrumb';

const UserSubscriptionsFilter = (props: any) => {
  return (
    <Filter {...props}>
      <TextInput label="First Name" source="user.firstName" />
      <TextInput label="Last Name" source="user.lastName" />
      <TextInput label="Email" source="user.email" />
    </Filter>
  );
};

export const UserSubscriptionsList = (props: any) => (
  <Card {...props}>
    <Title title="User Subscription" />
    <CardContent {...props}>
      <Breadcrumb routes={[{ text: 'User Subscription' }]} />
      <List
        {...props}
        filters={<UserSubscriptionsFilter />}
        bulkActionButtons={false}
        exporter={false}
      >
        <Datagrid rowClick="edit">
          <TextField source="user.firstName" label="First Name" />
          <TextField source="user.lastName" label="Last Name" />
          <EmailField source="user.email" label="E-mail" />
          <TextField source="subscription.name" label="Subscription" />
          <DateField
            source="expirationDate"
            label="Expiration Date"
            options={{
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
              timeZone: 'UTC',
            }}
            locales="en-US"
          />

          <EditButton />
        </Datagrid>
      </List>
    </CardContent>
  </Card>
);
