import { CustomRoute } from 'react-admin';
import { ChallengeWeekList } from 'modules/challengeWeeks/views';
import { ExerciseList } from 'modules/exercises/views';
import { ChallengeProgressList } from 'modules/challengeProgress/views';
import { Route } from 'react-router-dom';
import RecoveryPassword from 'modules/recoveryPassword/RecoveryPassword';
import ConfirmRecovery from 'modules/recoveryPassword/ConfirmRecovery';
import { ChallengeOverviewList } from 'modules/challengeOverviews/views';
import { Settings } from 'modules/settings';

export default [
  <Route
    key="challengeWeekExercise"
    path="/challenge/:challengeId/week/:challengeWeekId/exercises"
    component={ExerciseList}
  />,
  <Route
    key="challengeWeek"
    path="/challenge/:challengeId/weeks"
    component={ChallengeWeekList}
  />,
  <Route<CustomRoute>
    key="recoveryPassword"
    exact
    path="/recovery"
    component={RecoveryPassword}
    noLayout
  />,
  <Route<CustomRoute>
    key="confirmRecovery"
    exact
    path="/confirmRecovery/:token"
    component={ConfirmRecovery}
    noLayout
  />,
  <Route
    key="challengeUsersProgress"
    path="/challenge/:challengeId/progress"
    component={ChallengeProgressList}
  />,
  <Route
    key="challengeOverviewSections"
    path="/challenge/:challengeId/sections"
    component={ChallengeOverviewList}
  />,
  <Route key="settings" path="/settings" component={Settings} />,
];
