import React, { useState, useEffect, Fragment, FC } from 'react';
import {
  List,
  Filter,
  TextInput,
  TextField,
  Title,
  Button,
  CreateButton,
  ShowButton,
  EditButton,
  useListContext,
  useNotify,
  downloadCSV,
  useRefresh,
  ButtonProps,
} from 'react-admin';
import { Link } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Box from '@material-ui/core/Box';
import Datagrid from 'components/Datagrid';
import Typography from '@material-ui/core/Typography';
import GridIcon from '@material-ui/icons/GridOn';
import PublishIcon from '@material-ui/icons/Publish';

import BottomToolbar from 'components/Toolbar';
import Breadcrumb from 'components/Breadcrumb';
import UploadDialog from 'components/UploadDialog';
import RedirectButton from 'components/redirect-button';
import { CustomListTopToolbar } from 'components/custom-list-top-toolbar';
import {
  getChallenge,
  getChallengeExercises,
  getChallengeExercisesTemplate,
} from 'services/challenge';
import { axiosInstance } from 'services/http';
import {
  RowError,
  UploadErrorDialog,
} from '../components/challenge-week-upload-error-dialog';
import { customExporter } from 'components/customExporter';
import { useChallengeWeekStyles } from './useChallengeWeekStyles';

interface ImportError {
  row: number;
  error: Record<string, string>;
}

const exporter = (weeks: any[]) => {
  const dataForExport = weeks.map(week => {
    const { weekNumber, createdAt, updatedAt } = week;

    return {
      Week: weekNumber === 0 ? 'Pre-Challenge' : weekNumber,
      'Created At': createdAt,
      'Updated At': updatedAt,
    };
  });

  customExporter(dataForExport, 'Challenge Weeks');
};

const ChallengeWeekFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="Week Number" source="weekNumber" />
  </Filter>
);

const WeekField = (props: any) => {
  const week =
    props.record.weekNumber === 0
      ? 'Pre-Challenge'
      : `${props.record.weekNumber}`;
  return (
    <TextField
      record={{ id: props.record.weekNumber, weekNumber: week }}
      source="weekNumber"
    />
  );
};

const ImportExercisesButton: FC<ButtonProps> = props => {
  const { data } = useListContext();
  const hasCreatedWeeks = Object.keys(data).length > 1;

  if (hasCreatedWeeks) return null;

  return <Button {...props} />;
};

const ExportExercisesButton = ({ challengeId }: { challengeId: string }) => {
  const classes = useChallengeWeekStyles();
  const { data } = useListContext();
  const notify = useNotify();

  const hasCreatedWeeks = Object.keys(data).length > 1;

  const onDownloadTemplateClick = async () => {
    try {
      const { data } = await getChallengeExercisesTemplate();
      downloadCSV(data, 'Exercises');
    } catch (error) {
      notify(
        error?.message || 'Failed to export template, try again later',
        'error',
      );
    }
  };

  const onExportClick = async () => {
    try {
      const { data } = await getChallengeExercises(challengeId);
      downloadCSV(data, 'Exercises');
    } catch (error) {
      notify(
        error?.message || 'Failed to export exercises, try again later',
        'error',
      );
    }
  };

  if (hasCreatedWeeks) {
    return (
      <Button
        startIcon={<GridIcon />}
        onClick={onExportClick}
        label="Download Exercise List"
        className={classes.exportButton}
      />
    );
  }

  return (
    <Button
      startIcon={<GridIcon />}
      onClick={onDownloadTemplateClick}
      label="Download Template"
      className={classes.exportButton}
    />
  );
};

const CreateWeekButton: FC<any> = props => (
  <CreateButton
    component={Link}
    to={{
      pathname: '/challenge-week/create',
      state: { record: { challengeId: props.challengeId } },
    }}
    label="Add new challenge week"
  />
);

const ShowExerciseButton: FC<any> = props => {
  if (props.record.weekNumber === 0) return null;

  const pathname = `/challenge/${props.record.challengeId}/week/${props.record.id}/exercises`;

  return (
    <ShowButton component={Link} to={{ pathname }} label="View Exercises" />
  );
};

const ShowEditButton: FC<any> = props => {
  if (props.record.weekNumber === 0) return null;
  return <EditButton {...props} />;
};

const ListEmpty: FC<any> = props => (
  <Box textAlign="center" m={1}>
    <Typography>
      No Challenge Week have been created for this challenge.
    </Typography>
    <CreateWeekButton {...props} />
  </Box>
);

export const ChallengeWeekList: FC<any> = props => {
  const [challengeName, setChallengeName] = useState('Challenge');
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [uploadErrors, setUploadErrors] = useState<RowError[]>([]);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const refresh = useRefresh();
  const notify = useNotify();

  const {
    match: {
      params: { challengeId },
    },
  } = props;

  const onImportClick = () => setShowUploadModal(true);

  const onUploadCancel = () => setShowUploadModal(false);

  const onUploadConfirm = () => {
    setShowUploadModal(false);
    refresh();
  };

  const onErrorsClose = () => {
    setShowErrorModal(false);
    setUploadErrors([]);
  };

  const onUpload = async (file: Blob | File) => {
    const formData = new FormData();
    formData.append('file', file);
    await axiosInstance.post(
      `/challenge/${challengeId}/challenge-week/import`,
      formData,
      { headers: { 'Content-Type': 'multipart/form-data' } },
    );
  };

  const onUploadError = (error: any) => {
    if (!error?.response) {
      notify('Failed to upload exercises, try again', 'error');
      return;
    }

    if (Array.isArray(error?.response?.data?.message)) {
      const rows = error?.response?.data?.message as ImportError[];
      const errors = rows.reduce<RowError[]>(
        (acc, { error, row }) =>
          acc.concat(Object.values(error).map(err => ({ row, error: err }))),
        [],
      );

      setUploadErrors(errors);
    }

    if (typeof error?.response?.data?.message === 'string') {
      const errors = [
        { row: undefined, error: error?.response?.data?.message },
      ];

      setUploadErrors(errors);
    }

    setShowUploadModal(false);
    setShowErrorModal(true);

    notify('Failed to upload exercises, try again', 'error');
    return;
  };

  useEffect(() => {
    const loadChallenge = async () => {
      try {
        const { data } = await getChallenge(challengeId);
        setChallengeName(data?.name || 'Challenge');
      } catch (error) {
        console.error(error);
      }
    };

    loadChallenge();
  }, []);

  return (
    <Card {...props}>
      <Title title="Challenge Weeks" />
      <CardContent {...props}>
        <Breadcrumb
          routes={[
            { to: '/#/challenge', text: 'Challenges' },
            { text: challengeName },
          ]}
        />
        <List
          basePath={'/challenge-week'}
          resource="challenge-week"
          filters={<ChallengeWeekFilter />}
          filter={{ 'challengeId||$eq': challengeId }}
          sort={{ field: 'weekNumber', order: 'ASC' }}
          bulkActionButtons={<Fragment />}
          actions={
            <CustomListTopToolbar
              hasCreate
              createButton={<CreateWeekButton challengeId={challengeId} />}
            >
              <ImportExercisesButton
                startIcon={<PublishIcon />}
                onClick={onImportClick}
                label="Load Exercise List"
              />
              <ExportExercisesButton challengeId={challengeId} />
            </CustomListTopToolbar>
          }
          exporter={exporter}
          empty={<ListEmpty challengeId={challengeId} />}
        >
          <Datagrid>
            <WeekField label="Week" />
            <ShowExerciseButton />
            <ShowEditButton />
          </Datagrid>
        </List>
      </CardContent>
      <BottomToolbar
        hasSaveButton={false}
        backButton={
          <RedirectButton
            label="Go Back"
            redirectTo={{ pathname: `/challenge/${challengeId}/show` }}
          />
        }
      />
      <UploadDialog
        title="Upload CSV File"
        label="Exercises"
        open={showUploadModal}
        onClose={onUploadCancel}
        onConfirm={onUploadConfirm}
        onUpload={onUpload}
        onError={onUploadError}
      />
      <UploadErrorDialog
        open={showErrorModal}
        errors={uploadErrors}
        onClose={onErrorsClose}
      />
    </Card>
  );
};
