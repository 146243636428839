import React, { FC } from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

interface Option {
  value: string;
  label: string;
  disabled?: boolean;
}

interface Props {
  label?: string;
  initialValue?: string;
  value?: string;
  choices: Option[];
  onChange: (value: string) => void;
}

const RadioButtonsGroup: FC<Props> = ({
  label,
  initialValue,
  value,
  choices,
  onChange,
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = (event.target as HTMLInputElement).value;
    onChange(value);
  };

  return (
    <FormControl component="fieldset">
      {label && <FormLabel component="legend">{label}</FormLabel>}
      <RadioGroup
        defaultValue={initialValue}
        value={value}
        onChange={handleChange}
        row
      >
        {choices.map(option => (
          <FormControlLabel
            key={option.value}
            value={option.value}
            control={<Radio color="primary" />}
            label={option.label}
            disabled={option.disabled}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default RadioButtonsGroup;
