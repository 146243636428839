import jsonexport, { UserOptionsWithHandlers } from 'jsonexport';
import { downloadCSV } from 'ra-core';

export const customExporter = (
  dataForExport: any,
  fileName: string,
  options?: UserOptionsWithHandlers,
): void => {
  jsonexport(dataForExport, options || {}, (err: any, csv: any) => {
    if (err) console.error(err);

    downloadCSV(csv, fileName); // download as 'posts.csv` file
  });
};
