import PersonIcon from '@material-ui/icons/Person';
import { ExerciseCreate, ExerciseEdit, ExerciseView } from './views';

export default {
  create: ExerciseCreate,
  edit: ExerciseEdit,
  show: ExerciseView,
  icon: PersonIcon,
  name: 'exercise',
};
