import React from 'react';
import { TextInput, FormDataConsumer } from 'react-admin';
import runtimeEnv from '@mars/heroku-js-runtime-env';
import IconButton from '@material-ui/core/IconButton';
import LaunchIcon from '@material-ui/icons/Launch';

import SimpleForm from 'components/SimpleForm';

const env = runtimeEnv();
export const SHOPIFY_URL = env?.REACT_APP_SHOPIFY_URL;

const ShopifyButton: React.FC = () => (
  <FormDataConsumer>
    {({ formData }) => (
      <IconButton
        size="small"
        color="default"
        disabled={!formData.shopifyPageOverviewRef}
        onClick={() => {
          const url = `${SHOPIFY_URL}/admin/pages/${formData.shopifyPageOverviewRef}`;
          window.open(url, '_blank');
        }}
      >
        <LaunchIcon />
      </IconButton>
    )}
  </FormDataConsumer>
);

export const OverviewForm = (props: any) => {
  const urlParams = new URLSearchParams(props.location.search);
  const challengeId = urlParams.get('challengeId');
  const redirectTo = challengeId
    ? `/challenge/${challengeId}/sections`
    : '/challenge';

  return (
    <SimpleForm {...props} redirectOnSave={() => redirectTo}>
      <TextInput
        type="string"
        fullWidth
        InputProps={{ inputProps: { maxLength: 60 } }}
        source="title"
        label="Section Title"
      />
      <TextInput
        type="string"
        fullWidth
        source="shopifyPageOverviewRef"
        label="Shopify Page ID"
      />
      <ShopifyButton />
    </SimpleForm>
  );
};
