import { makeStyles } from '@material-ui/core/styles';

export const useDeviceStyles = makeStyles({
  fields: {
    width: '100% !important',
    display: 'flex',
    justifyContent: 'space-between',
  },
  createBox: {
    maxWidth: '100%',
  },
  content: {
    maxWidth: '100%',
  },
  halfWidth: {
    width: '50%',
    margin: '0px 15px',
  },
  table: {
    width: '30%',
    minWidth: '400px',
    border: '1px solid rgb(224, 224, 224)',
    '& th': {
      padding: 5,
    },
    '& td': {
      padding: 5,
      textAlign: 'left',
      borderLeft: '1px solid rgb(224, 224, 224)',
    },
    '& button': {
      height: 20,
      marginLeft: 30,
      textAlign: 'right',
      borderLeft: '1px solid rgb(224, 224, 224)',
    },
  },
  actionButtons: {
    '& > *': {
      marginRight: '30px',
    },
  },
  headerImage: {
    paddingBottom: 20,
    height: 150,
    maxWidth: 300,
  },
  fullName: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  label: {
    color: 'rgb(0, 0, 0, 0.54)',
    padding: 0,
    paddingBottom: '4px',
    fontSize: '12px',
    letterSpacing: '0.00938em',
  },
  labelField: {
    padding: '4px 0 4px',
    display: 'block',
  },
  deleteButton: {
    width: 100,
    float: 'right',
    marginRight: 20,
    marginBottom: 20
  },

});
