import * as React from 'react';
import { Show } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';

import { useExerciseStyles } from './useExerciseStyles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Box from '@material-ui/core/Box';
import BottomToolbar from '../../../components/Toolbar';

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const ShowExercise = (props: any) => {
  const { record } = props;

  const classes = useExerciseStyles();

  return (
    <Box>
      <Box
        display="flex"
        flexDirection="column"
        m={3}
        className={classes.content}
      >
        <Box mb={3}>{record.title}</Box>

        <Box>
          <Table className={classes.table} aria-label="customized table">
            <TableBody>
              <StyledTableRow key="participants">
                <StyledTableCell component="th" scope="row">
                  Weight
                </StyledTableCell>
                <StyledTableCell align="right">{record.weight}</StyledTableCell>
              </StyledTableRow>
              <StyledTableRow key="startDate">
                <StyledTableCell component="th" scope="row">
                  Sets
                </StyledTableCell>
                <StyledTableCell align="right">
                  {record.setCount}
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow key="endDate">
                <StyledTableCell component="th" scope="row">
                  Reps
                </StyledTableCell>
                <StyledTableCell align="right">
                  {record.repCount}
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow key="active">
                <StyledTableCell component="th" scope="row">
                  Rest
                </StyledTableCell>
                <StyledTableCell align="right">
                  {record.restSeconds}
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow key="active">
                <StyledTableCell component="th" scope="row">
                  Video Url
                </StyledTableCell>
                <StyledTableCell align="right">
                  {record.videoUrl}
                </StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </Box>
        <Box mt={3} mb={3}>
          {record.comments}
        </Box>
      </Box>
      <BottomToolbar hasSaveButton={false} />
    </Box>
  );
};

export const ExerciseView = (props: any) => {
  const classes = useExerciseStyles();

  return (
    <Show {...props} className={classes.createBox}>
      <ShowExercise {...props} />
    </Show>
  );
};
