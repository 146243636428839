import PersonIcon from '@material-ui/icons/Person';
import {
  ExercisesLibraryList,
  ExercisesLibraryCreate,
  ExercisesLibraryView,
} from './views';

export default {
  create: ExercisesLibraryCreate,
  edit: ExercisesLibraryCreate,
  show: ExercisesLibraryView,
  list: ExercisesLibraryList,
  icon: PersonIcon,
  name: 'exercise-library',
  options: { label: 'Exercises Library' },
};
