// exercises-list

import React, { FC, useEffect, useState } from 'react';
import {
  List,
  Filter,
  TextInput,
  TextField,
  ShowButton,
  Title,
  CreateButton,
  EditButton,
  SelectInput,
  BulkDeleteButton,
} from 'react-admin';
import { CustomListTopToolbar } from 'components/custom-list-top-toolbar';
import { Link } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import { OrderButtons } from 'components/order-buttons';
import Breadcrumb from 'components/Breadcrumb';
import BottomToolbar from 'components/Toolbar';
import Datagrid from 'components/Datagrid';
import { customExporter } from 'components/customExporter';
import RedirectButton from 'components/redirect-button';
import { WEEK_DAYS } from 'constants/constants';
import DayOfWeekField from '../components/day-of-week-field';
import { getChallenge, getChallengeWeek } from 'services/challenge';

const CreateExerciseButton: FC<any> = props => {
  const { challengeId, challengeWeekId } = props;
  const search = `challengeId=${challengeId}&challengeWeekId=${challengeWeekId}`;

  return (
    <CreateButton
      component={Link}
      to={{
        pathname: `/exercise/create`,
        state: { record: { challengeWeekId } },
        search,
      }}
      label="Add new exercise"
    />
  );
};

const EditExerciseButton: FC<any> = props => {
  const { challengeId, challengeWeekId, record } = props;
  const search = `challengeId=${challengeId}&challengeWeekId=${challengeWeekId}`;
  return (
    <EditButton
      component={Link}
      to={{ pathname: `/exercise/${record.id}`, search }}
    />
  );
};

const exporter = (list: any[]) => {
  const dataForExport = list.map((data: any) => {
    const {
      title = '',
      location = '',
      weekDay = '',
      videoUrl = '',
      comments = '',
      weight = '',
      setCount = '',
      repCount = '',
      restSeconds = '',
      sortPriority = '',
    } = data;

    const weekDayTxt = WEEK_DAYS.find(({ id }) => id == weekDay);

    return {
      Title: `${title}`,
      Location: location,
      'Week Day': weekDayTxt?.day,
      'Video Url': videoUrl,
      Comments: comments,
      Weight: weight,
      'Set Count': setCount,
      'Rep Count': repCount,
      'Rest Seconds': restSeconds,
      'Sort Priority': sortPriority,
    };
  });

  customExporter(dataForExport, 'Exercises');
};

const ListEmpty: FC<any> = props => (
  <Box textAlign="center" m={1}>
    <Typography>No exercise has been added for this week.</Typography>
    <CreateExerciseButton {...props} />
  </Box>
);

const ExerciseFilter: FC<any> = props => (
  <Filter {...props}>
    <TextInput label="Title" source="title" />
    <SelectInput
      label="Day of Week"
      source="weekDay"
      choices={WEEK_DAYS.map(weekDay => {
        return { id: weekDay.id, name: weekDay.day };
      })}
    />
    <SelectInput
      label="Location"
      source="location"
      choices={[
        { id: 'home', name: 'Home' },
        { id: 'gym', name: 'Gym' },
      ]}
    />
  </Filter>
);

export const ExerciseList: FC<any> = props => {
  const [weekNumber, setWeekNumber] = useState('Week');
  const [challengeName, setChallengeName] = useState('Challenge');

  const {
    match: {
      params: { challengeId, challengeWeekId },
    },
  } = props;

  useEffect(() => {
    const loadData = async () => {
      try {
        const { data: challenge } = await getChallenge(challengeId);
        setChallengeName(challenge?.name || 'Challenge');

        const { data: challengeWeek } = await getChallengeWeek(challengeWeekId);
        setWeekNumber(`Week ${challengeWeek?.weekNumber || ''}`);
      } catch (error) {
        console.error(error);
      }
    };

    loadData();
  }, []);

  const BulkActions = (props: any) => (
    <BulkDeleteButton {...props} undoable={false} />
  );

  return (
    <Card {...props}>
      <Title title="Exercises" />
      <CardContent {...props}>
        <Breadcrumb
          routes={[
            { to: '/#/challenge', text: 'Challenges' },
            { text: challengeName, to: `/#/challenge/${challengeId}/show` },
            { text: weekNumber },
          ]}
        />
        <List
          {...props}
          bulkActionButtons={<BulkActions />}
          syncWithLocation={true}
          basePath={`/exercise`}
          resource="exercise"
          filters={<ExerciseFilter />}
          filter={{ 'challengeWeekId||$eq': challengeWeekId }}
          sort={{ field: 'sortPriority', order: 'ASC' }}
          actions={
            <CustomListTopToolbar
              hasCreate={true}
              createButton={
                <CreateExerciseButton
                  challengeId={challengeId}
                  challengeWeekId={challengeWeekId}
                />
              }
            />
          }
          empty={
            <ListEmpty
              challengeId={challengeId}
              challengeWeekId={challengeWeekId}
            />
          }
          exporter={exporter}
        >
          <Datagrid>
            <TextField source="title" label="Title" />
            <DayOfWeekField label="Day of week" />
            <TextField source="location" label="Location" />
            <OrderButtons source="sortPriority" />
            <EditExerciseButton
              challengeId={challengeId}
              challengeWeekId={challengeWeekId}
            />
            <ShowButton />
          </Datagrid>
        </List>
      </CardContent>
      <BottomToolbar
        hasSaveButton={false}
        backButton={
          <RedirectButton
            label="Go Back"
            redirectTo={{
              pathname: `/challenge/${challengeId}/weeks`,
            }}
          />
        }
      />
    </Card>
  );
};
