import { FC } from 'react';
import { Create, Edit } from 'react-admin';
import { validateLibraryExerciseCreation } from './exercises-library-validation';
import { useExerciseStyles } from './useExerciseStyles';
import { LibraryExerciseForm } from '../components/exercises-library-edit-form';
import Breadcrumb from 'components/Breadcrumb';

export const ExercisesLibraryCreate: FC<any> = props => {
  const { id } = props;
  const classes = useExerciseStyles();

  const Container = id ? Edit : Create;

  return (
    <>
      <Breadcrumb
        routes={[
          { to: `/#/exercise-library`, text: 'Exercise' },
          { text: id ? 'Edit' : 'New' },
        ]}
      />
      <Container
        {...props}
        mutationMode="pessimistic"
        className={classes.createBox}
      >
        <LibraryExerciseForm
          {...props}
          validate={validateLibraryExerciseCreation}
        />
      </Container>
    </>
  );
};
