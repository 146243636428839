import React, { useState, useEffect } from 'react';
import {
  List,
  TextField,
  EditButton,
  ShowButton,
  BulkDeleteButton,
  CreateButton,
} from 'react-admin';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import Breadcrumb from 'components/Breadcrumb';
import Datagrid from 'components/Datagrid';
import { getChallenge } from 'services/challenge';
import { OrderButtons } from 'components/order-buttons';
import { CustomListTopToolbar } from 'components/custom-list-top-toolbar';
import { useChallengeStyles } from './useChallengeStyles';

const EditOverviewButton = ({ record }: any) => {
  const { id, challengeId } = record;
  return (
    <EditButton
      to={{
        pathname: `/section/${id}`,
        state: { record: { challengeId } },
        search: `challengeId=${challengeId}`,
      }}
    />
  );
};

const ShowOverviewButton = ({ record }: any) => {
  const { id, challengeId } = record;
  return (
    <ShowButton
      to={{
        pathname: `/section/${id}/show`,
        state: { record: { challengeId } },
        search: `challengeId=${challengeId}`,
      }}
    />
  );
};

const CreateOverviewButton = ({ challengeId, ...props }: any) => (
  <CreateButton
    {...props}
    to={{
      pathname: `/section/create`,
      state: { record: { challengeId } },
      search: `challengeId=${challengeId}`,
    }}
  />
);

const BulkActions = (props: any) => (
  <BulkDeleteButton
    {...props}
    confirmTitle="Overview Section"
    confirmContent="Are you sure you want to delete the selected sections? This action cannot be undone"
    undoable={false}
  />
);

export const ChallengeOverviewList = (props: any) => {
  const [challengeName, setChallengeName] = useState('Challenge');
  const classes = useChallengeStyles();

  const {
    match: {
      params: { challengeId },
    },
  } = props;

  useEffect(() => {
    const loadChallenge = async () => {
      try {
        const { data } = await getChallenge(challengeId);
        setChallengeName(data?.name || 'Challenge');
      } catch (error) {
        console.error(error);
      }
    };

    loadChallenge();
  }, []);

  return (
    <>
      <Breadcrumb
        routes={[
          { to: '/#/challenge', text: 'Challenges' },
          { to: `/#/challenge/${challengeId}/show`, text: challengeName },
          { text: 'Overview' },
        ]}
      />
      <List
        {...props}
        title="Overview"
        exporter={false}
        syncWithLocation
        resource="section"
        basePath="/section"
        bulkActionButtons={<BulkActions />}
        filter={{ 'challengeId||$eq': challengeId }}
        sort={{ field: 'sortPriority', order: 'ASC' }}
        empty={
          <Box textAlign="center" m={10}>
            <Box mb={2}>
              <Typography>
                No overview section have been created for this challenge.
              </Typography>
            </Box>
            <CreateOverviewButton challengeId={challengeId} />
          </Box>
        }
        actions={
          <CustomListTopToolbar
            hasCreate={true}
            createButton={<CreateOverviewButton challengeId={challengeId} />}
          />
        }
      >
        <Datagrid classes={{ table: classes.table }}>
          <TextField source="title" label="Section Title" sortable={false} />
          <OrderButtons source="sortPriority" />
          <EditOverviewButton />
          <ShowOverviewButton />
        </Datagrid>
      </List>
    </>
  );
};
