import { useState, useEffect } from 'react';
import { SettingsData, dataProvider } from 'providers/dataProvider';

interface useSettingsInterface {
  (): {
    showPrograms: boolean | null;
    setShowPrograms: (value: boolean) => void;
    shopifyApiKey: string;
    setShopifyApiKey: (value: string) => void;
    doUpdateSettings: (settingsData: SettingsData) => any;
    updateSettingsError: string | null;
  };
}

export const useSettings: useSettingsInterface = () => {
  const [showPrograms, setShowPrograms] = useState<boolean | null>(null);
  const [shopifyApiKey, setShopifyApiKey] = useState<string>('');
  const [updateSettingsError, setUpdateSettingsError] = useState<string | null>(
    null,
  );

  const { updateSettings, getSettings } = dataProvider;

  const getSettingsFromApi = async () => {
    try {
      const settings = await getSettings();
      if (settings?.data) {
        setShowPrograms(settings.data.showPrograms);
        setShopifyApiKey(settings.data.shopifyApiKey);
      }
    } catch (error) {
      setUpdateSettingsError('Error to get settings. Please try again.');
    }
  };

  useEffect(() => {
    getSettingsFromApi();
  }, []);

  const doUpdateSettings = async (settingsData: SettingsData) => {
    try {
      setUpdateSettingsError(null);
      const res = await updateSettings(settingsData);
      return res;
    } catch (error) {
      console.log('error', error);
      setUpdateSettingsError('Error on settings save. Please try again.');
    }
  };

  return {
    showPrograms,
    setShowPrograms,
    shopifyApiKey,
    setShopifyApiKey,
    doUpdateSettings,
    updateSettingsError,
  };
};
