import * as React from 'react';
import { Edit } from 'react-admin';
import { validateChallengeWeekCreation } from './challengeWeekValidation';
import { useChallengeWeekStyles } from './useChallengeWeekStyles';
import { ChallengeForm } from '../components/challenge-week-edit-form';

export const ChallengeWeekEdit = (props: any) => {
  const classes = useChallengeWeekStyles();

  return (
    <Edit {...props} undoable={false} className={classes.createBox}>
      <ChallengeForm validate={validateChallengeWeekCreation} />
    </Edit>
  );
};
