interface Ierrors {
  weekNumber?: string;
}

export const validateChallengeWeekCreation = (values: any) => {
  const errors: Ierrors = {};

  if (!(values.weekNumber >= 0)) {
    errors.weekNumber = 'The field weekNumber is required';
  }
  return errors;
};
