import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  input: {
    margin: '0px 15px',
    flexGrow: 1,
  },
  fields: {
    width: '100% !important',
    display: 'flex',
    justifyContent: 'space-between',
  },
  createBox: {
    maxWidth: '1500px',
  },
  halfWidth: {
    width: '50%',
    margin: '0px 15px',
  },
  deleteButton: {
    width: 100,
    float: 'right',
    marginRight: 20,
    marginBottom: 20,
  },
  deleteRequestDateField: {
    color: '#EB5757',
  },
});
