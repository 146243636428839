import React from 'react';
import {
  List,
  EmailField,
  EditButton,
  Filter,
  TextInput,
  DateField,
  NumberField,
  TextField,
  ChipField,
  BooleanInput,
} from 'react-admin';
import Datagrid from 'components/Datagrid';
import { useStyles } from './useStyles';
import { customExporter } from 'components/customExporter';

const UserFilter = (props: any) => {
  return (
    <Filter {...props}>
      <BooleanInput
        label="Account deletion request"
        source="deleteRequestedDate||$notnull"
        alwaysOn
      />
      <TextInput label="First Name" source="firstName" awaysOn />
      <TextInput label="Last Name" source="lastName" />
      <TextInput label="Email" source="email" />
      <TextInput label="Challenge" source="userChallenges.challenge.name" />
    </Filter>
  );
};

const ChallengesNames = (props: any) => {
  const { record } = props;
  return record?.userChallenges?.map((userChallenge: any) => {
    return (
      <ChipField
        key={userChallenge.id}
        record={userChallenge}
        source="challenge.name"
      />
    );
  });
};

const exporter = (users: any) => {
  const usersForExport = users.map((user: any) => {
    const {
      firstName,
      lastName,
      email,
      dateOfBirth,
      heightInches,
      userChallenges,
      active,
      createdAt,
      updatedAt,
    } = user;

    return {
      'First Name': firstName,
      'Last Name': lastName,
      Active: active ? 'Yes' : 'No',
      Email: email,
      'Date Of Birth': dateOfBirth,
      'Height (Inches)': heightInches,
      Challenges: userChallenges?.map((userChallenge: any) => {
        return userChallenge?.challenge?.name;
      }),
      'Created At': createdAt,
      'Updated At': updatedAt,
    };
  });

  customExporter(usersForExport, 'Users');
};

export const UserList = (props: any) => {
  const classes = useStyles();

  return (
    <List
      {...props}
      filters={<UserFilter />}
      bulkActionButtons={<React.Fragment />}
      exporter={exporter}
    >
      <Datagrid rowClick="edit">
        <TextField source="firstName" label="First Name" />
        <TextField source="lastName" label="Last Name" />
        <EmailField source="email" label="Email" />
        <DateField
          source="dateOfBirth"
          label="Date of Birth"
          options={{ timeZone: 'UTC' }}
        />
        <NumberField source="heightInches" label="Height (Inches)" />
        <ChallengesNames label="Challenges" />
        <DateField
          className={classes.deleteRequestDateField}
          source="deleteRequestedDate"
          label="Delete Request Date"
          options={{ timeZone: 'UTC' }}
        />
        <EditButton />
      </Datagrid>
    </List>
  );
};
