import { useEffect, useState } from 'react';
import { Button, PasswordInput, useDataProvider } from 'react-admin';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { ShowNotification } from '../views/notifications-view';
import Clear from '@material-ui/icons/Clear';
import { useNotificationsStyles } from '../views/useNotificationsStyles';

const ConfirmationModal = ({
  open,
  handleClose,
  record,
  children,
  challenges,
  subscriptions,
}: any) => {
  const [targetDevicesCount, setTargetDevicesCount] = useState<
    number | string | undefined
  >(undefined);

  const classes = useNotificationsStyles();

  const formatedChallenges = record?.challenges?.map(
    (chlg: string) => challenges[chlg],
  );
  const formatedSubscriptions = record?.subscriptions?.map(
    (subs: string) => subscriptions[subs],
  );

  const dataProvider = useDataProvider();

  const wait = (ms: number) =>
    new Promise(resolve => setTimeout(() => resolve(ms), ms));

  const retryWithDelay: any = async (
    fn: any,
    retries = 3,
    interval = 500,
    finalErr = 'Retry failed',
  ) => {
    try {
      await fn();
    } catch (err) {
      if (retries <= 0) {
        setTargetDevicesCount('error');
        return Promise.reject(finalErr);
      }

      await wait(interval);

      return retryWithDelay(fn, retries - 1, interval, finalErr);
    }
  };

  const getPreviewData = () => {
    return async () => {
      try {
        const previewData = await dataProvider.getNotificationUsersPreview(
          'notification-preview',
          {
            type: record.type,
            ids:
              record.type === 'segmentedByChallenge'
                ? record.challenges
                : record.subscriptions,
          },
        );

        if (previewData?.data) {
          setTargetDevicesCount(previewData.data.targetDevicesCount);
        }
        return previewData?.data?.targetDevicesCount;
      } catch (error) {
        console.log('error', error);
        throw new Error();
      }
    };
  };

  const tryGetPreviewData = async () => {
    setTargetDevicesCount(undefined);

    try {
      await retryWithDelay(getPreviewData(), 5);
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    if (open) {
      tryGetPreviewData();
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="Confirmation modal"
      aria-describedby="A modal to confirm your form data"
    >
      <Box className={classes.confirmationModal}>
        <Typography style={{ fontSize: 24, fontWeight: 400 }}>
          Are you sure you want to send this message?
        </Typography>

        <ShowNotification
          record={{
            ...record,
            createdAt: new Date(),
            challenges: formatedChallenges,
            subscriptions: formatedSubscriptions,
          }}
          targetDevicesCount={targetDevicesCount}
          isSendReview
        />
      </Box>

      <Box
        display="flex"
        justifyContent="center"
        className={classes.confirmationModal}
      >
        <PasswordInput
          source="pushNotificationPassword"
          label="Password"
          inputProps={{ autocomplete: 'new-password' }}
          autoComplete="new-password"
        />
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        className={classes.confirmationModal}
      >
        <Button
          label="CANCEL"
          onClick={handleClose}
          className={classes.cancelButton}
          alignIcon="right"
        >
          <Clear />
        </Button>
        {children}
      </Box>
    </Dialog>
  );
};

export default ConfirmationModal;
