interface Ierrors {
  name: string;
  shopifyProductRef: string;
}

export const validateSubscriptionCreation = (values: any) => {
  const errors: Partial<Ierrors> = {};
  if (!values.name || !values.name?.trim()) {
    errors.name = 'Name is required.';
  }

  if (!values.shopifyProductRef || !values.shopifyProductRef?.trim()) {
    errors.shopifyProductRef = 'Shopify Product Id is required.';
  }

  return errors;
};
