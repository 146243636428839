import {
  List,
  Filter,
  TextField,
  TextInput,
  DateInput,
  ShowButton,
  Title,
  CreateButton,
  DateField,
  ReferenceInput,
  SelectInput,
  ArrayField,
  SingleFieldList,
  ChipField,
  useRecordContext,
} from 'react-admin';
import { Link } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import SendIcon from '@material-ui/icons/Send';
import { zonedTimeToUtc } from 'date-fns-tz';
import Breadcrumb from 'components/Breadcrumb';
import Datagrid from 'components/Datagrid';
import { useNotificationsStyles } from './useNotificationsStyles';
import { FC } from 'react';
import { CustomListTopToolbar } from 'components/custom-list-top-toolbar';

const CreateNewNotificationButton = () => {
  const classes = useNotificationsStyles();

  return (
    <CreateButton
      component={Link}
      to={{ pathname: `/notification/create` }}
      label="Send new notification"
      variant="contained"
      icon={<></>}
      endIcon={<SendIcon />}
      className={classes.createButton}
    />
  );
};

const ListEmpty: FC<any> = props => (
  <Box textAlign="center" m={1}>
    <Typography>No notification has been created yet.</Typography>
    <CreateNewNotificationButton {...props} />
  </Box>
);

const NotificationsFilter: FC<any> = props => {
  const classes = useNotificationsStyles();

  const parseDateToUsEastern = (v: any) => {
    if (v) {
      return zonedTimeToUtc(v, 'US/Eastern').toISOString();
    }
  };

  return (
    <Filter {...props}>
      <TextInput label="Title" source="title" />
      <TextInput label="Message" source="message" />
      <DateInput
        className={classes.dateInput}
        source="createdAt||$gte"
        label="Created Date After"
        parse={parseDateToUsEastern}
      />
      <DateInput
        className={classes.dateInput}
        source="createdAt||$lte"
        label="Created Date Before"
        parse={parseDateToUsEastern}
      />
      <ReferenceInput
        label="Target"
        reference="challenge"
        source="challenges.id"
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
    </Filter>
  );
};

const CustomTypeField: FC<any> = props => {
  const { source } = props;

  const record = useRecordContext();
  const isChallenge = record.type === 'segmentedByChallenge';

  if (record?.[source] === 'all') {
    return <Box>All</Box>;
  }

  return (
    <ArrayField
      source={isChallenge ? 'challenges' : 'notificationSubscriptions'}
      sortBy={isChallenge ? 'name' : 'subscription.name'}
    >
      <SingleFieldList linkType={false}>
        <ChipField source={isChallenge ? 'name' : 'subscription.name'} />
      </SingleFieldList>
    </ArrayField>
  );
};

const TargetTotal: FC<any> = props => {
  const record = useRecordContext();

  if (record?.status === 'pending') {
    return <Box>Sending</Box>;
  }

  return <TextField emptyText="No data" {...props} />;
};

export const NotificationsList: FC<any> = props => (
  <Card {...props}>
    <Title title="Notifications" />
    <CardContent {...props}>
      <Breadcrumb routes={[{ text: 'Notifications' }]} />
      <List
        {...props}
        filters={<NotificationsFilter />}
        sort={{ field: 'createdAt', order: 'DESC' }}
        empty={<ListEmpty />}
        bulkActionButtons={false}
        exporter={false}
        actions={
          <CustomListTopToolbar
            hasCreate={true}
            createButton={<CreateNewNotificationButton />}
          />
        }
      >
        <Datagrid>
          <TextField source="title" label="Title" />
          <TextField source="message" label="Message" />
          <TargetTotal source="numberOfDevicesSent" label="Target Total" />x
          <DateField
            source="createdAt"
            label="Created Date"
            options={{
              timeZone: 'US/Eastern',
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
              second: '2-digit',
            }}
            showTime
            emptyText="-"
          />
          <CustomTypeField source="type" label="Target" />
          <ShowButton />
        </Datagrid>
      </List>
    </CardContent>
  </Card>
);
