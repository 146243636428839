import { useState, useEffect, useRef } from 'react';
import {
  TextField,
  DateField,
  List,
  Filter,
  TextInput,
  FunctionField,
  BooleanInput,
  useNotify,
  useRefresh,
  FunctionFieldProps,
} from 'react-admin';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import BlockIcon from '@material-ui/icons/Block';

import Breadcrumb from 'components/Breadcrumb';
import Datagrid from 'components/Datagrid';
import { customExporter } from 'components/customExporter';
import { useChallengeCommentsStyles } from './useChallengeCommentsStyles';
import { toggleCommentBlock, toggleCommentVisible } from 'services/comment';
import { getChallenge } from 'services/challenge';
import ConfirmDialog from 'components/ConfirmDialog';

interface CommentLike {
  id: string;
  like: boolean;
}

interface Comment {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  createdAt: string;
  description: string;
  likes: number;
  dislikes: number;
  isVisible: boolean;
  moderatorBlock: boolean;
  moderatorVisible: boolean | null;
  challengeCommentLikes?: CommentLike[];
}

const VisibilityField = (props: any) => {
  const [loading, setLoading] = useState(false);
  const notify = useNotify();
  const refresh = useRefresh();
  const classes = useChallengeCommentsStyles();

  return (
    <FunctionField
      {...props}
      render={(record: Comment) => (
        <Button
          disabled={loading}
          onClick={() => {
            if (!record) return;
            const { id, moderatorVisible } = record;
            setLoading(true);
            toggleCommentVisible({
              id,
              moderatorVisible:
                moderatorVisible === null ? false : !moderatorVisible,
            })
              .then(() => refresh())
              .catch(err => notify(err.message, 'error'));
            setLoading(true);
          }}
          className={`${classes.button} ${
            record?.isVisible ? classes.visible : classes.hidden
          }`}
          startIcon={
            !loading &&
            (record?.isVisible ? <VisibilityIcon /> : <VisibilityOffIcon />)
          }
        >
          {!loading && (record?.isVisible ? 'Visible' : 'Hidden')}
          {loading && <CircularProgress size={20} />}
        </Button>
      )}
    />
  );
};

interface BlockFieldProps extends Omit<FunctionFieldProps, 'render'> {
  onBlockClick: (comment: Comment) => void;
}

const BlockField = ({ onBlockClick, ...rest }: BlockFieldProps) => {
  const classes = useChallengeCommentsStyles();
  return (
    <FunctionField
      {...rest}
      render={(record: any) => (
        <Button
          onClick={() => onBlockClick(record)}
          className={`${classes.button} ${
            record.moderatorBlock ? classes.blocked : classes.unblocked
          }`}
          startIcon={<BlockIcon />}
        >
          {record.moderatorBlock ? 'Unblock' : 'Block'}
        </Button>
      )}
    />
  );
};

const CommentsFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="Email" source="email" />
    <BooleanInput label="Visible" source="isVisible" defaultValue={true} />
    <BooleanInput label="Blocked" source="moderatorBlock" defaultValue={true} />
    <TextInput
      label="Downvote greater than"
      source="dislikes||$gt"
      type="number"
    />
  </Filter>
);

const exporter = (comments: Comment[]) => {
  const dataForExport = comments.map(comment => {
    const {
      email,
      firstName,
      lastName,
      description,
      dislikes,
      likes,
      createdAt,
      isVisible,
      moderatorBlock,
    } = comment;

    return {
      Name: `${firstName} ${lastName}`,
      Email: email,
      Message: description.replaceAll(/(\r\n|\n|\r)/gm, ''),
      Upvote: likes,
      Downvote: dislikes,
      'Created at': createdAt,
      Blocked: moderatorBlock,
      Visible: isVisible,
    };
  });

  customExporter(dataForExport, 'Comments', { rowDelimiter: ';' });
};

export const ChallengeCommentsList = (props: any) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const [challengeName, setChallengeName] = useState('Challenge');
  const [showBlockConfirmModal, setShowBlockConfirmModal] = useState(false);
  const selectedComment = useRef<Comment>();

  const {
    match: {
      params: { id },
    },
  } = props;

  const handleBlockComment = () => {
    if (!selectedComment.current) return;

    const { id, moderatorBlock } = selectedComment.current;
    toggleCommentBlock({ id, moderatorBlock: !moderatorBlock })
      .then(() => {
        setShowBlockConfirmModal(false);
        selectedComment.current = undefined;
        refresh();
      })
      .catch(err => notify(err.message, 'error'));
  };

  const handleOpenBlockCommentModal = (comment: Comment) => {
    selectedComment.current = comment;

    if (comment.moderatorBlock) {
      handleBlockComment();
    } else {
      setShowBlockConfirmModal(true);
    }
  };

  useEffect(() => {
    const loadChallenge = async () => {
      try {
        const { data } = await getChallenge(id);
        setChallengeName(data?.name || 'Challenge');
      } catch (error) {
        console.error(error);
      }
    };

    loadChallenge();
  }, []);

  return (
    <>
      <Breadcrumb
        routes={[
          { to: '/#/challenge', text: 'Challenges' },
          { to: `/#/challenge/${id}/show`, text: challengeName },
          { text: 'Comments' },
        ]}
      />
      <List
        {...props}
        filters={<CommentsFilter />}
        filter={{ 'challengeId||$eq': id }}
        filterDefaultValues={{ isVisible: true, moderatorBlock: false }}
        sort={{ field: 'createdAt', order: 'DESC' }}
        hasCreate={false}
        title="Comments"
        basePath="/challenge-comment-report"
        resource="challenge-comment-report"
        bulkActionButtons={false}
        exporter={exporter}
      >
        <Datagrid>
          <TextField source="email" label="Email" emptyText="N/A" />
          <TextField
            source="firstName"
            label="First Name"
            emptyText="Deleted"
          />
          <TextField source="lastName" label="Last Name" emptyText="N/A" />
          <TextField source="description" label="Message" emptyText="-" />
          <TextField source="likes" label="Upvote" emptyText="-" />
          <TextField source="dislikes" label="Downvote" emptyText="-" />
          <DateField
            source="createdAt"
            label="Date"
            showTime
            options={{ timeZone: 'US/Eastern' }}
            emptyText="-"
          />
          <VisibilityField />
          <BlockField onBlockClick={handleOpenBlockCommentModal} />
        </Datagrid>
      </List>
      <ConfirmDialog
        open={showBlockConfirmModal}
        onClose={() => setShowBlockConfirmModal(false)}
        onConfirm={handleBlockComment}
        title="Are you sure you want to block this comment/reply?"
        content="Once blocked, the Users won't be able to view it."
      />
    </>
  );
};
