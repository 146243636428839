import { fetchUtils, DataProvider } from 'react-admin';
import { axiosInstance } from 'services/http';
import {
  CondOperator,
  QueryFilter,
  QuerySort,
  RequestQueryBuilder,
} from '@nestjsx/crud-request';
// TODO This is a uuid hack to filter works based on a uuid
import { validate as uuidValidate } from 'uuid';
import { OrderButtonsDirection } from 'components/order-buttons';
import compact from 'lodash/compact';

export interface SettingsData {
  showPrograms: boolean;
  shopifyApiKey: string;
}

const httpClient = fetchUtils.fetchJson;

const getMessage = (error: any) => {
  const message =
    error?.response && error?.response?.data && error?.response?.data?.message;
  if (!message) {
    return error?.response?.statusText;
  }
  return typeof message !== 'string'
    ? error?.response?.data?.message[0]
    : error?.response?.data?.message;
};

const composeFilter = (resource: string, paramsFilter: any): QueryFilter[] => {
  if (
    paramsFilter &&
    (paramsFilter === '' ||
      (typeof paramsFilter.q !== 'undefined' && paramsFilter.q === ''))
  ) {
    paramsFilter = {};
  }

  const flatFilter = fetchUtils.flattenObject(paramsFilter);

  const filters: (QueryFilter | undefined)[] = Object.keys(flatFilter).map(
    key => {
      const splitKey = key.split('||');

      let field = splitKey[0];
      let ops = splitKey[1];
      if (!ops) {
        if (
          typeof flatFilter[key] === 'number' ||
          typeof flatFilter[key] === 'boolean' ||
          (flatFilter[key].match && flatFilter[key].match(/^\d+$/)) ||
          uuidValidate(flatFilter[key]) ||
          (resource === 'exercise' && key === 'location') ||
          (resource === 'device' && key === 'deviceType')
        ) {
          ops = CondOperator.EQUALS;
        } else {
          ops = CondOperator.CONTAINS;
        }
      }

      if (field.startsWith('_') && field.includes('.')) {
        field = field.split(/\.(.+)/)[1];
      }

      if (resource === 'user' && key === 'deleteRequestedDate||$notnull') {
        if (flatFilter[key] === false) {
          return undefined;
        }
      }

      return { field, operator: ops, value: flatFilter[key] } as QueryFilter;
    },
  );

  return compact(filters);
};

export const dataProvider: DataProvider = {
  getList: (resource, params) =>
    new Promise((resolve, reject) => {
      const newResource = resource === 'users' ? 'user' : resource;
      const { page, perPage } = params.pagination;
      const query = RequestQueryBuilder.create({
        filter: composeFilter(resource, params.filter),
      })
        .setLimit(perPage)
        .setPage(page)
        .sortBy(params.sort as QuerySort)
        .setOffset((page - 1) * perPage)
        .query();
      const url = `${newResource}?${query}`;
      return axiosInstance
        .get(url)
        .then(response =>
          resolve({
            data: response.data.data,
            total: response.data.total,
          }),
        )
        .catch(error => {
          const message = getMessage(error);

          error.message = message;
          return reject(error);
        });
    }),
  getOne: (resource, params) =>
    axiosInstance.get(`/${resource}/${params.id}`).then(response => ({
      data: response.data,
    })),
  getMany: (resource, params) =>
    new Promise((resolve, reject) => {
      const query = RequestQueryBuilder.create()
        .setFilter({
          field: 'id',
          operator: CondOperator.IN,
          value: `${params.ids}`,
        })
        .query();
      const url = `${resource}?${query}`;

      return axiosInstance
        .get(url)
        .then(response =>
          resolve({
            data: response.data.data,
          }),
        )
        .catch(error => {
          const message = getMessage(error);

          error.message = message;
          return reject(error);
        });
    }),
  getManyReference: (resource, params) =>
    new Promise((resolve, reject) => {
      const { page, perPage } = params.pagination;
      const filter: QueryFilter[] = composeFilter(resource, {});
      if (params.id && params.target) {
        filter.push({
          field: params.target,
          operator: CondOperator.EQUALS,
          value: params.id,
        });
      }

      const query = RequestQueryBuilder.create({
        filter,
      })
        .sortBy(params.sort as QuerySort)
        .setLimit(perPage)
        .setOffset((page - 1) * perPage)
        .query();

      const url = `${resource}?${query}`;
      return axiosInstance
        .get(url)
        .then(({ data }) =>
          resolve({
            data: data.data,
            total: data.total,
          }),
        )
        .catch(error => {
          const message = getMessage(error);

          error.message = message;
          return reject(error);
        });
    }),
  update: (resource, params) => {
    // TODO: refactor this code
    if (resource === 'challenge') {
      delete params.data.userChallenges;
      delete params.data.content;
    }

    return new Promise((resolve, reject) =>
      axiosInstance
        .patch(`${resource}/${params.id}`, params.data)
        .then(response =>
          resolve({
            data: response.data,
          }),
        )
        .catch(error => {
          const message = getMessage(error);

          error.message = message;
          return reject(error);
        }),
    );
  },
  updateMany: (resource, params) => {
    return httpClient(`${resource}`, {
      method: 'PUT',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));
  },
  create: (resource, params) => {
    return new Promise((resolve, reject) =>
      axiosInstance
        .post(`${resource}`, params.data)
        .then(response =>
          resolve({
            data: response.data,
          }),
        )
        .catch(error => {
          const message = getMessage(error);

          error.message = message;
          return reject(error);
        }),
    );
  },
  delete: (resource, params) =>
    new Promise((resolve, reject) =>
      axiosInstance
        .delete(`${resource}/${params.id}`)
        .then(({ data }) => resolve({ data }))
        .catch(error => {
          const message = getMessage(error);

          error.message = message;
          return reject(error);
        }),
    ),
  deleteMany: (resource, params) => {
    return new Promise((resolve, reject) => {
      return Promise.all(
        params.ids.map(id => axiosInstance.delete(`${resource}/${id}`)),
      )
        .then(responses =>
          resolve({ data: responses.map(response => response.data) }),
        )
        .catch(error => {
          const message = getMessage(error);

          error.message = message;
          return reject(error);
        });
    });
  },
  move: (
    resource: string,
    params: {
      id: number | string;
      direction: OrderButtonsDirection;
    },
  ) =>
    new Promise((resolve, reject) =>
      axiosInstance
        .patch(
          `${resource}/${params.id}/move`,
          JSON.stringify({
            direction: params.direction,
          }),
        )
        .then(response =>
          resolve({
            data: response,
          }),
        )
        .catch(error => {
          const message = getMessage(error);

          error.message = message;
          return reject(error);
        }),
    ),
  getNotificationUsersPreview: (
    resource: string,
    { type, ids }: { type: string; ids: string[] },
  ) => {
    const isChallenge = type === 'segmentedByChallenge';
    const params = {
      type,
      [isChallenge ? 'challengeIds' : 'subscriptionIds']: ids,
    };

    return axiosInstance
      .get(resource, {
        params,
      })
      .then(response => ({
        data: response.data,
      }));
  },

  getHighlights: () =>
    axiosInstance.get('/shopify-content/article').then(response => ({
      data: response.data,
    })),

  getSettings: () =>
    axiosInstance.get('/setting').then(response => ({
      data: response.data,
    })),
  updateSettings: (params: SettingsData) => {
    return new Promise((resolve, reject) =>
      axiosInstance
        .patch('setting', params)
        .then(response =>
          resolve({
            data: response.data,
          }),
        )
        .catch(error => {
          const message = getMessage(error);

          error.message = message;
          return reject(error);
        }),
    );
  },
};

export default dataProvider;
