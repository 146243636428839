import { makeStyles } from '@material-ui/core/styles';

export const useChallengeCommentsStyles = makeStyles({
  button: {
    textTransform: 'none',
  },
  visible: {
    color: '#27AE60',
  },
  hidden: {
    color: '#F2994A',
  },
  blocked: {
    color: '#C4C4C4',
  },
  unblocked: {
    color: '#EB5757',
  },
});
