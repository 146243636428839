import * as React from 'react';
import { TextInput, useInput } from 'react-admin';
import { useStyles } from '../views/useStyles';

export const UserHeight = (props: any) => {
  const classes = useStyles();
  const {
    input: { value, onChange },
  } = useInput(props);

  const toFeet = (heightInches: number) => {
    const newInches = heightInches ? heightInches : 0;
    return Math.floor(newInches / 12);
  };

  const toInches = (heightInches: number) => {
    const newInches = heightInches ? heightInches : 0;
    return newInches % 12;
  };

  const parseHeight = (feet: any, inches: any) => {
    const newFeet = feet ? feet : 0;
    const newInches = inches ? inches : 0;
    return Number(newFeet) * 12 + Number(newInches);
  };

  const changeFeet = (e: any) => {
    const ft = e.target.value;
    const newValue = parseHeight(ft, toInches(value));
    return onChange({
      target: {
        value: newValue,
      },
    });
  };

  const changeInches = (e: any) => {
    const inches = e.target.value;
    const newValue = parseHeight(toFeet(value), inches);

    return onChange({
      target: {
        value: newValue,
      },
    });
  };

  return (
    <React.Fragment>
      <TextInput
        className={classes.input}
        format={toFeet}
        type="number"
        onChange={changeFeet}
        parse={parseHeight}
        id="Feet"
        source="heightInches"
        label="Height Ft"
        defaultValue={0}
      />
      <TextInput
        className={classes.input}
        format={toInches}
        id="Inches"
        onChange={changeInches}
        parse={parseHeight}
        type="number"
        source="heightInches"
        label="Height In"
        defaultValue={0}
      />
    </React.Fragment>
  );
};
