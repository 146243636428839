import Rating from '@material-ui/lab/Rating';
import { useInput } from 'ra-core';

export const RatingInput = (props: any) => {
  const {
    input: { value, onChange },
  } = useInput(props);

  const onRatingChange = (event: any, newValue: any) => {
    if (!event) return;

    onChange({
      target: {
        value: newValue || 0,
      },
    });
  };

  return (
    <Rating
      name="half-rating"
      onChange={onRatingChange}
      value={value}
      precision={0.5}
    />
  );
};
