import Dialog from '@material-ui/core/Dialog';

import { makeStyles } from '@material-ui/core/styles';

export const SimpleImageDialog = (props:any) => {
  const { onClose, url, open } = props;
  
  const useStyles = makeStyles(() => ({
    image: {
      height: "100%",
    },
  }));

  const classes = useStyles();

  const handleClose = () => {
    onClose();
  }; 
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <img
        src={url}
        className={classes.image}
      />
    </Dialog>
  );
};
   