import { useEffect, useState } from 'react';
import { Create } from 'react-admin';

import Breadcrumb from 'components/Breadcrumb';
import { getChallenge } from 'services/challenge';
import { OverviewForm } from '../components/overview-edit-form';
import { validateOverviewCreation } from './overviewValidation';
import { useChallengeStyles } from './useChallengeStyles';

export const ChallengeOverviewCreate = (props: any) => {
  const classes = useChallengeStyles();
  const [challengeName, setChallengeName] = useState('Challenge');

  const {
    location: { search },
  } = props;

  const urlParams = new URLSearchParams(search);
  const id = urlParams.get('challengeId');

  useEffect(() => {
    const loadChallenge = async () => {
      try {
        if (!id) return;

        const { data } = await getChallenge(id);
        setChallengeName(data?.name || 'Challenge');
      } catch (error) {
        console.error(error);
      }
    };

    loadChallenge();
  }, []);

  return (
    <>
      <Breadcrumb
        routes={[
          { to: '/#/challenge', text: 'Challenges' },
          { to: `/#/challenge/${id}/show`, text: challengeName },
          { to: `/#/challenge/${id}/sections`, text: 'Overview' },
          { text: 'Content' },
        ]}
      />
      <Create {...props} className={classes.createBox}>
        <OverviewForm {...props} validate={validateOverviewCreation} />
      </Create>
    </>
  );
};
