import { FC } from 'react';
import {
  TextInput,
  BooleanInput,
  ReferenceInput,
  SelectInput,
  SelectArrayInput,
  ReferenceArrayInput,
} from 'react-admin';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import SimpleForm from 'components/SimpleForm';
import VideoPreview from 'components/VideoPreview';
import { useExerciseStyles } from '../views/useExerciseStyles';
import { HingeEnum, LevelEnum, VectorEnum } from '@darihana/common';

type Option = {
  id: string;
  name: string;
};

export const LibraryExerciseForm: FC<any> = props => {
  const classes = useExerciseStyles();

  const hingeOptions: Option[] = (Object.keys(
    HingeEnum,
  ) as (keyof typeof HingeEnum)[]).map(key => {
    console.log(key);
    return { id: HingeEnum[key], name: key };
  });

  const levelOptions: Option[] = (Object.keys(
    LevelEnum,
  ) as (keyof typeof LevelEnum)[]).map(key => {
    console.log(key);
    return { id: LevelEnum[key], name: key };
  });
  const vectorOptions: Option[] = (Object.keys(
    VectorEnum,
  ) as (keyof typeof VectorEnum)[]).map(key => {
    console.log(key);
    return { id: VectorEnum[key], name: key };
  });

  return (
    <SimpleForm {...props}>
      <Box display="flex" className={classes.fields}>
        <TextInput
          source="title"
          type="string"
          fullWidth
          InputProps={{ inputProps: { maxLength: 100 } }}
        />
        <BooleanInput
          source="availableToBeSelected"
          className={classes.input}
          label="Available to be Selected"
          fullWidth
          defaultValue={true}
        />
      </Box>

      <TextInput
        source="videoUrl"
        type="string"
        fullWidth
        InputProps={{ inputProps: { maxLength: 150 } }}
      />

      <VideoPreview source="videoUrl" />

      <TextInput
        source="comments"
        label="Default comment"
        fullWidth
        multiline
        rows={3}
        InputLabelProps={{ shrink: true }}
        InputProps={{ inputProps: { maxLength: 500 } }}
      />

      <Typography className={classes.labels}>Information</Typography>
      <Box display="flex" flexDirection="row" className={classes.fields}>
        <Grid container spacing={2} className={classes.selectGrid}>
          <Grid item xs={6}>
            <SelectInput fullWidth source="level" choices={levelOptions} />
          </Grid>
          <Grid item xs={6}>
            <SelectInput fullWidth source="vector" choices={vectorOptions} />
          </Grid>
        </Grid>
      </Box>

      <Box display="flex" flexDirection="row" className={classes.fields}>
        <Grid container spacing={2} className={classes.selectGrid}>
          <Grid item xs={4}>
            <SelectInput fullWidth source="hinge" choices={hingeOptions} />
          </Grid>
          <Grid item xs={4}>
            <ReferenceInput
              label="Primary Muscle Group"
              source="primaryMuscleGroupId"
              reference="muscle-group"
            >
              <SelectInput optionText="name" optionValue="id" fullWidth />
            </ReferenceInput>
          </Grid>
          <Grid item xs={4}>
            <ReferenceInput
              label="Secondary Muscle Group"
              source="secondaryMuscleGroupId"
              reference="muscle-group"
            >
              <SelectInput optionText="name" optionValue="id" fullWidth />
            </ReferenceInput>
          </Grid>
          <Grid item xs={12}>
            <ReferenceArrayInput
              source="equipments"
              reference="equipment"
              parse={(value: string[]) => {
                return value?.map(id => ({ id }));
              }}
              format={(value: Array<any>) => {
                return value?.map(obj => obj.id);
              }}
            >
              <SelectArrayInput optionText="name" optionValue="id" fullWidth />
            </ReferenceArrayInput>
          </Grid>
        </Grid>
      </Box>

      <Typography className={classes.labels}>Default Exercise</Typography>

      <Box display="flex" flexDirection="row">
        <TextInput
          className={classes.marginRight}
          label="Weight"
          type="string"
          source="weight"
          InputProps={{ inputProps: { maxLength: 11 } }}
        />
        <TextInput
          label="SetCount"
          type="number"
          source="setCount"
          InputProps={{ inputProps: { max: 100 } }}
        />
      </Box>
      <Box display="flex" flexDirection="row">
        <TextInput
          className={classes.marginRight}
          label="RepCount"
          type="number"
          source="repCount"
          InputProps={{ inputProps: { max: 100 } }}
        />
        <TextInput
          label="Rest Time"
          type="string"
          source="restSeconds"
          InputProps={{ inputProps: { maxLength: 10 } }}
        />
      </Box>
    </SimpleForm>
  );
};
