import * as React from 'react';
import { TextInput } from 'react-admin';

import SimpleForm from '../../../components/SimpleForm';

export const ChallengeForm = (props: any) => {
  return (
    <SimpleForm
      {...props}
      redirectOnSave={() => '/challenge/' + props.record.challengeId + '/weeks'}
      backTo={{
        pathname: `/challenge/${props.record.challengeId}/weeks`,
      }}
    >
      <TextInput type="number" fullWidth source="weekNumber" />
    </SimpleForm>
  );
};
