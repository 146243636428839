import { makeStyles } from '@material-ui/core/styles';

export const useChallengeWeekStyles = makeStyles({
  fields: {
    width: '100% !important',
    display: 'flex',
    justifyContent: 'space-between',
  },
  createBox: {
    maxWidth: '100%',
  },
  content: {
    maxWidth: '100%',
  },
  halfWidth: {
    width: '50%',
    margin: '0px 15px',
  },
  table: {
    width: '30%',
    minWidth: '400px',
    border: '1px solid rgb(224, 224, 224)',
    '& th': {
      padding: 5,
    },
    '& td': {
      padding: 5,
      textAlign: 'left',
      borderLeft: '1px solid rgb(224, 224, 224)',
    },
    '& button': {
      height: 20,
      marginLeft: 30,
      textAlign: 'right',
      borderLeft: '1px solid rgb(224, 224, 224)',
    },
  },
  actionButtons: {
    '& > *': {
      marginRight: '30px',
    },
  },
  exportButton: {
    padding: '4px 8px',
    '& .MuiButton-startIcon': {
      marginRight: '0px',
    },
  },
});
