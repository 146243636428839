import React from 'react';
import { SaveButton, Toolbar as ToolBarRA } from 'react-admin';
import { Button } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  buttonBack: {
    marginRight: 'auto',
  },
});

export const Toolbar: React.FC<any> = props => {
  const classes = useStyles();
  const {
    redirectOnSave,
    hasSaveButton = true,
    backButton,
    customSaveButton,
  } = props;
  const redirect = redirectOnSave || 'list';

  return (
    <ToolBarRA {...props}>
      {backButton || (
        <Button
          variant="contained"
          color="primary"
          size="medium"
          className={classes.buttonBack}
          startIcon={<ChevronLeftIcon />}
          onClick={() => {
            window.history.back();
          }}
        >
          Go Back
        </Button>
      )}

      {customSaveButton
        ? customSaveButton
        : hasSaveButton && <SaveButton redirect={redirect} />}
    </ToolBarRA>
  );
};

export default Toolbar;
