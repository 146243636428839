import * as React from 'react';
import {
  List,
  EditButton,
  Filter,
  TextInput,
  DateField,
  TextField,
  ShowButton,
  ChipField,
} from 'react-admin';
import Datagrid from 'components/Datagrid';
import { customExporter } from 'components/customExporter';


const ChallengeFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="Name" source="name" />
  </Filter>
);

const MemberCountField = (props: any) => {
  const { record } = props;
  const count = record?.userChallenges?.length;
  return <ChipField record={{ id: 0, total: count }} source="total" />;
};


const exporter = (challenges:any) => {
  const usersForExport = challenges.map((challenge:any) => {
    const {
      name,
      headerImage,
      description,
      content,
      fbGroupUrl,
      shopifyProductRef,
      shopifyPageOverviewRef,
      active,
      startDate,
      endDate,
      userChallenges
    } = challenge;

    return {
      "Name": name,
      "Image": headerImage,
      "Description": description,
      "Content": content,
      "Facebook Group Url": fbGroupUrl,
      "shopify Product Id": shopifyProductRef,
      "shopify Challenge Overview Id": shopifyPageOverviewRef,
      "Active": active ? "Yes" : "No",
      "Start Date": startDate,
      "End Date": endDate,
      "Participants":  userChallenges?.length
    };
  });

  customExporter(usersForExport, "Challenges");
 
};

export const ChallengeList = (props: any) => {
  return (
    <List
      {...props}
      filters={<ChallengeFilter />}
      bulkActionButtons={<React.Fragment />}
      sort={{ field: 'startDate', order: 'DESC' }}
      exporter={exporter}
    >
      <Datagrid>
        <TextField source="name" label="Name" />
        <DateField source="startDate" label="Start Date" options={{ timeZone: 'UTC' }}/>
        <DateField source="endDate" label="End Date" options={{ timeZone: 'UTC' }}/>
        <MemberCountField label="Participants"/>
        {/* <ReferenceManyField
          label="Participants"
          reference="user-challenge"
          target="challengeId"
        >
          <MemberCountField />
        </ReferenceManyField> */}
        <EditButton />
        <ShowButton />
      </Datagrid>
    </List>
  );
};
