import React, { useState, useEffect } from 'react';
import { Show } from 'react-admin';
import runtimeEnv from '@mars/heroku-js-runtime-env';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import LaunchIcon from '@material-ui/icons/Launch';

import BottomToolbar from 'components/Toolbar';
import Breadcrumb from 'components/Breadcrumb';
import { getChallenge } from 'services/challenge';
import { useChallengeStyles } from './useChallengeStyles';

const env = runtimeEnv();
export const SHOPIFY_URL = env?.REACT_APP_SHOPIFY_URL;

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const ShowOverview = (props: any) => {
  const { record } = props;
  const classes = useChallengeStyles();
  return (
    <Box>
      <Box
        display="flex"
        flexDirection="column"
        m={3}
        className={classes.content}
      >
        <Box mb={3}>Overview Section</Box>
        <Box>
          <Table className={classes.viewTable} aria-label="customized table">
            <TableBody>
              <StyledTableRow key="title">
                <StyledTableCell component="th" scope="row">
                  Title
                </StyledTableCell>
                <StyledTableCell align="right">{record?.title}</StyledTableCell>
              </StyledTableRow>
              <StyledTableRow key="shopifyPageOverviewRef">
                <StyledTableCell component="th" scope="row">
                  Shopify Page ID
                </StyledTableCell>
                <StyledTableCell align="right">
                  <Box alignItems="center">
                    {record?.shopifyPageOverviewRef}
                    <IconButton
                      size="small"
                      color="default"
                      disabled={!record?.shopifyPageOverviewRef}
                      onClick={() => {
                        const url = `${SHOPIFY_URL}/admin/pages/${record?.shopifyPageOverviewRef}`;
                        window.open(url, '_blank');
                      }}
                    >
                      <LaunchIcon />
                    </IconButton>
                  </Box>
                </StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </Box>
      </Box>
      <BottomToolbar hasSaveButton={false} />
    </Box>
  );
};

export const ChallengeOverviewView = (props: any) => {
  const [challengeName, setChallengeName] = useState('Challenge');
  const classes = useChallengeStyles();

  const {
    location: { search },
  } = props;

  const urlParams = new URLSearchParams(search);
  const challengeId = urlParams.get('challengeId');

  useEffect(() => {
    const loadChallenge = async () => {
      try {
        if (!challengeId) return;
        const { data } = await getChallenge(challengeId);
        setChallengeName(data?.name || 'Challenge');
      } catch (error) {
        console.error(error);
      }
    };

    loadChallenge();
  }, []);

  return (
    <>
      <Breadcrumb
        routes={[
          { to: '/#/challenge', text: 'Challenges' },
          { to: `/#/challenge/${challengeId}/show`, text: challengeName },
          { to: `/#/challenge/${challengeId}/sections`, text: 'Overview' },
          { text: 'Content' },
        ]}
      />
      <Show {...props} className={classes.createBox}>
        <ShowOverview {...props} />
      </Show>
    </>
  );
};
