import PersonIcon from '@material-ui/icons/Person';
import {
  ChallengeList,
  ChallengeCreate,
  ChallengeEdit,
  ChallengeView,
} from './views';

export default {
  create: ChallengeCreate,
  edit: ChallengeEdit,
  list: ChallengeList,
  show: ChallengeView,
  icon: PersonIcon,
  name: 'challenge',
};
