import React, { useState, Fragment } from 'react';
import {
  TextInput,
  BooleanInput,
  DateInput,
  ImageField,
  useInput,
  SelectArrayInput,
  useGetList,
} from 'react-admin';
import Box from '@material-ui/core/Box';
import { axiosInstance } from 'services/http';
import SimpleForm from '../../../components/SimpleForm';
import { Uppy } from '@uppy/core';
import { FileInput, useUppy } from '@uppy/react';
import AwsS3 from '@uppy/aws-s3';
import { useChallengeStyles } from '../views/useChallengeStyles';
import { API_UPLOAD_PUBLIC } from 'services/constants';
import '@uppy/core/dist/style.css';
import '@uppy/file-input/dist/style.css';
import '@uppy/progress-bar/dist/style.css';
interface SubscriptionChallenge {
  challengeId: string;
}
const HeaderImage = (props: any) => {
  const styles = useChallengeStyles();

  if (!props?.record?.id) return <React.Fragment />;

  const {
    input: { value, onChange },
  } = useInput(props);

  const setImage = (newImage: string) => {
    return onChange({
      target: {
        value: newImage,
      },
    });
  };
  const basePath = `challenge_${props?.record?.id}_`;

  const uppy = useUppy(() => {
    return new Uppy({
      meta: { type: 'avatar' },
      restrictions: {
        allowedFileTypes: ['image/*', '.jpg', '.jpeg', '.png'],
      },
      onBeforeFileAdded: currentFile => {
        // change file name in order to force selecting the same file
        const modifiedFile = {
          ...currentFile,
          name: `${currentFile.name}_${Date.now()}`,
        };
        return modifiedFile;
      },
      autoProceed: true,
    })
      .use(AwsS3, {
        getUploadParameters(file) {
          const v = new Date().getTime();
          const filename = `headerImage_${v}.${file.extension}`;

          // TODO: Need to use dataProvider hook if possible
          // Send a request to our PHP signing endpoint.
          return axiosInstance
            .post('/uploads/sign', {
              filename: `${basePath}_${filename}`,
              contentType: file.type,
              objectName: `${basePath}_${filename}`,
            })
            .then(({ data }) => {
              // Return an object in the correct shape.
              return {
                method: 'PUT',
                url: data.signedUrl,
                fields: data.fields,
                // Provide content type header required by S3
                headers: {
                  'Content-Type': file.type,
                },
              };
            });
        },
      })
      .on('upload-success', (file, response) => {
        if (!file) return;

        const name = response.uploadURL?.split('/')?.pop();
        setImage(`${name}`);

        // the S3 object key of the uploaded file
      });
  });

  const fullPath = `${API_UPLOAD_PUBLIC}/${value}`;

  return (
    <Fragment>
      <FileInput
        uppy={uppy}
        pretty={true}
        inputName="file"
        locale={{
          strings: {
            chooseFiles: 'Choose Challenge Image',
          },
        }}
      />

      {value && (
        <ImageField
          record={{ id: 0, url: fullPath }}
          source="url"
          className={styles.headerImage}
        />
      )}
    </Fragment>
  );
};

const SocialSettings = (props: any) => {
  const [isGroupChat, setIsGroupChat] = useState(props?.record?.isGroupChat);

  return (
    <Fragment>
      <BooleanInput
        source="isGroupChat"
        label="Group Chat"
        onChange={setIsGroupChat}
        defaultValue={true}
      />
      <TextInput
        label="Facebook Group Url"
        type="string"
        fullWidth
        source="fbGroupUrl"
        disabled={isGroupChat}
      />
    </Fragment>
  );
};

const getSubscriptions = (challengeId: string) => {
  const { data } = useGetList(
    'subscription',
    {
      page: 1,
      perPage: 1000,
    },
    {
      field: 'name',
      order: 'ASC',
    },
  );

  // list of All Subscription active and inactive
  const subscriptionsList = Object.values(data).sort((a, b) =>
    a.name.localeCompare(b.name),
  );
  const activeSubscriptions = subscriptionsList.filter(a => a.active === true);

  // list of subscriptions that belongs to challenge
  // Even if subscription is inactive it should still display
  const subscriptionOnChallenge = subscriptionsList.filter(a => {
    // get only subscription that is associated to the challenge
    const subscriptionsAssociated = a.subscriptionChallenges.filter(
      (subscriptionChallenge: SubscriptionChallenge) =>
        subscriptionChallenge.challengeId === challengeId,
    );
    return subscriptionsAssociated.length > 0;
  });

  // combine actives subscription with the ones associated
  const listAllNotUnique = [...activeSubscriptions, ...subscriptionOnChallenge];

  // make sure don't have duplicates
  return Array.from(new Set(listAllNotUnique));
};
export const ChallengeForm = (props: any) => {
  const styles = useChallengeStyles();
  const challengeId = props?.record?.id;

  const dateParseWithTz = (date: string) => {
    if (!date) return;
    // return a date with a timezone set
    return new Date(date + ' 00:00:00').toISOString();
  };

  // get subscriptions to be display
  const subscriptions = getSubscriptions(challengeId);

  return (
    <SimpleForm {...props}>
      <HeaderImage source="headerImage" />
      <TextInput type="string" fullWidth source="name" />
      <TextInput
        type="string"
        fullWidth
        source="shopifyProductRef"
        label="Shopify Product Id"
      />
      <TextInput
        label="Shopify Challenge Overview Page Id"
        type="string"
        fullWidth
        source="shopifyPageOverviewRef"
      />
      <TextInput
        fullWidth
        multiline
        rows={3}
        source="description"
        InputLabelProps={{ shrink: true }}
      />
      <Box display="flex" className={styles.fullWidth}>
        <Box flex={1}>
          <BooleanInput source="active" defaultValue={false} />
          <Box
            display="flex"
            flexDirection="column"
            className={styles.containedInput}
          >
            <DateInput type="date" source="startDate" parse={dateParseWithTz} />
            <DateInput type="date" source="endDate" parse={dateParseWithTz} />
          </Box>
        </Box>
        <Box flex={1}>
          <SelectArrayInput
            choices={subscriptions}
            translateChoice={false}
            label="Subscription"
            source="subscriptionChallenges"
            optionText="name"
            optionValue="id"
            fullWidth
            parse={(value: string[]) => {
              return value?.map(id => ({ subscriptionId: id }));
            }}
            format={(value: Array<any>) => {
              return value?.map(obj => obj.subscriptionId);
            }}
          />
        </Box>
      </Box>

      <SocialSettings />
    </SimpleForm>
  );
};
