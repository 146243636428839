import * as React from 'react';
import { TextInput, TextField, Record as RARecord } from 'react-admin';
import Typography from '@material-ui/core/Typography';

import Breadcrumb from 'components/Breadcrumb';
import SimpleForm from '../../../components/SimpleForm';
import { RatingInput } from 'components/Rating/RatingInput';
import { useChallengeProgressStyles } from '../views/useChallengeProgressStyles';
import { ChallengeWeek } from './challenge-progress-week';
import { SimpleImageDialog } from 'components/Popup/simple-image-dialog';

interface Photo {
  metaData: Record<string, unknown>;
  relativePath: string;
  url: string;
}

interface Progress {
  id: string;
  userChallengeId: string;
  challengeWeekId: string;
  notes: string | null;
  startWeightLbs: number;
  endWeightLbs: number;
  photos: Photo[];
  createdAt: string;
  updatedAt: string;
  comments: string | null;
  rating: number | null;
  challengeWeek: Week;
}

interface User {
  id: string;
  firstName: string;
  lastName: string;
}

export interface Week {
  id: string;
  challengeId: string;
  weekNumber: number;
  createdAt: string;
  updatedAt: string;
  sortPriority: number;
}

interface Challenge {
  id: string;
  name: string;
  challengeWeeks: Week[];
}

export interface ChallengeProgress extends RARecord {
  name: string;
  challenge: Challenge;
  notes: string;
  rating: number;
  user: User;
  userChallengeProgresses: Progress[];
  createdAt: string;
  updatedAt: string;
}

interface ChallengeProgressFormProps {
  record: ChallengeProgress;
}

export const ChallengeProgressForm = (
  props: ChallengeProgressFormProps,
): JSX.Element => {
  const { record } = props;

  const classes = useChallengeProgressStyles();
  const [open, setOpen] = React.useState(false);
  const [modalImageUrl, setModalImageUrl] = React.useState('');

  const fullName = `${record?.user?.firstName || ''} ${
    record?.user?.lastName || ''
  }`;

  const handleOpen = (url: string) => {
    setOpen(true);
    setModalImageUrl(url);
  };

  const handleClose = () => setOpen(false);

  return (
    <SimpleForm
      {...props}
      backTo={{ pathname: `/challenge/${record?.challenge?.id}/progress` }}
      redirectOnSave={() => `/challenge/${record?.challenge?.id}/progress`}
    >
      <Breadcrumb
        routes={[
          { to: '/#/challenge', text: 'Challenges' },
          {
            to: `/#/challenge/${record?.challenge?.id}/show`,
            text: record?.challenge?.name || 'Challenge',
          },
          {
            to: `/#/challenge/${record?.challenge?.id}/progress`,
            text: 'Progress',
          },
          { text: fullName },
        ]}
      />
      <SimpleImageDialog
        open={open}
        onClose={handleClose}
        url={modalImageUrl}
      />
      <Typography className={classes.label}>User</Typography>
      <Typography className={classes.labelField}>{fullName}</Typography>
      <TextField source="challenge.name" label="Challenge" />
      {record?.challenge?.challengeWeeks
        ?.sort((weekA, weekB) => (weekA.weekNumber > weekB.weekNumber ? 1 : -1))
        .map((week, index) => (
          <ChallengeWeek
            key={`week_${index.toString()}`}
            week={week}
            onImageOpen={handleOpen}
          />
        ))}
      <Typography className={classes.label}>Rating</Typography>
      <RatingInput
        source="rating"
        label="Rating"
        className={classes.labelField}
      />
      <TextInput
        label="Notes"
        source="notes"
        type="string"
        multiline
        fullWidth
        rows={3}
      />
    </SimpleForm>
  );
};
