import {
  List,
  Filter,
  TextField,
  TextInput,
  AutocompleteInput,
  DateInput,
  ShowButton,
  Title,
  CreateButton,
  EditButton,
  DateField,
  FunctionField,
  FunctionFieldProps,
  Record,
  useGetList,
  SelectInput,
} from 'react-admin';
import { Link } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Breadcrumb from 'components/Breadcrumb';
import Datagrid from 'components/Datagrid';
import { customExporter } from 'components/customExporter';
import { useExerciseStyles } from './useExerciseStyles';
import { FC } from 'react';

type LinkFieldProps = Omit<FunctionFieldProps, 'render'>;

const LinkField: FC<LinkFieldProps> = props => (
  <FunctionField
    {...props}
    render={(record?: Record, source?: string) => {
      if (!record || !source) return null;
      const link = record[source];

      return (
        <a href={link} target="_blank" rel="noreferrer">
          {link}
        </a>
      );
    }}
  />
);

const CreateLibraryExerciseButton = () => (
  <CreateButton
    component={Link}
    to={{ pathname: `/exercise-library/create` }}
    label="Add new library exercise"
  />
);

const exporter = (list: any[]) => {
  const dataForExport = list.map((data: any) => {
    const { title, videoUrl, comments, availableToBeSelected } = data;

    return {
      Title: title,
      'Video Url': videoUrl,
      Comments: comments,
      'Available To Be Selected': availableToBeSelected ? 'Yes' : 'No',
    };
  });

  customExporter(dataForExport, 'Exercises');
};

const ListEmpty: FC<any> = props => (
  <Box textAlign="center" m={1}>
    <Typography>No exercises have been added yet.</Typography>
    <CreateLibraryExerciseButton {...props} />
  </Box>
);

const ExercisesLibraryFilter: FC<any> = props => {
  const { data, loading, loaded } = useGetList(
    'exercise-library',
    {
      page: 1,
      perPage: 1000,
    },
    {
      field: 'title',
      order: 'ASC',
    },
  );

  const classes = useExerciseStyles();

  return (
    <Filter {...props}>
      <AutocompleteInput
        label="Title"
        source="title||$cont"
        allowEmpty={false}
        optionText="title"
        optionValue="title"
        suggestionLimit={25}
        choices={Object.values(data).sort((a, b) =>
          a.title.localeCompare(b.title),
        )}
        alwaysOn
        resettable
        loading={loading}
        loaded={loaded}
      />
      <TextInput label="Video URL" source="videoUrl" />
      <DateInput
        className={classes.dateInput}
        source="updatedAt||$lte"
        label="Last Update Before"
      />
      <DateInput
        className={classes.dateInput}
        source="updatedAt||$gte"
        label="Last Update After"
      />
      <SelectInput
        label="Available to be Selected"
        source="availableToBeSelected"
        emptyText="All"
        choices={[
          { id: true, name: 'Available' },
          { id: false, name: 'Unavailable' },
        ]}
        fullWidth
        InputProps={{ style: { minWidth: '240px' } }}
      />
    </Filter>
  );
};

export const ExercisesLibraryList: FC<any> = props => (
  <Card {...props}>
    <Title title="Exercises" />
    <CardContent {...props}>
      <Breadcrumb routes={[{ text: 'Exercises Library' }]} />
      <List
        {...props}
        filters={<ExercisesLibraryFilter />}
        sort={{ field: 'title', order: 'ASC' }}
        empty={<ListEmpty />}
        exporter={exporter}
        bulkActionButtons={false}
      >
        <Datagrid>
          <TextField source="title" label="Title" />
          <LinkField source="videoUrl" label="Video URL" />
          <TextField source="comments" label="Default Comments" />
          <DateField
            source="updatedAt"
            label="Last Update"
            options={{ day: '2-digit', month: '2-digit', year: 'numeric' }}
            locales="en-US"
          />
          <EditButton />
          <ShowButton />
        </Datagrid>
      </List>
    </CardContent>
  </Card>
);
