import { makeStyles } from '@material-ui/core/styles';

export const useNotificationsStyles = makeStyles(theme => ({
  labels: {
    fontSize: 24,
    marginTop: 4,
  },
  marginTop: {
    marginTop: 8,
  },
  input: {
    display: 'flex',
    justifyContent: 'center',
    whiteSpace: 'nowrap',
    marginLeft: '15px',
    [theme.breakpoints.up(1024)]: {
      marginLeft: '80px',
    },
  },
  fields: {
    width: '100% !important',
    display: 'flex',
    justifyContent: 'space-between',
  },
  createBox: {
    maxWidth: '100%',
  },
  content: {
    maxWidth: '100%',
  },
  table: {
    width: '50%',
    minWidth: '400px',
    border: '1px solid rgb(224, 224, 224)',
    '& th': {
      padding: 5,
    },
    '& td': {
      padding: 5,
      textAlign: 'left',
      borderLeft: '1px solid rgb(224, 224, 224)',
    },
    '& button': {
      height: 20,
      marginLeft: 30,
      textAlign: 'right',
      borderLeft: '1px solid rgb(224, 224, 224)',
    },
  },
  actionButtons: {
    '& > *': {
      marginRight: '30px',
    },
  },
  dateInput: {
    minWidth: '160px',
  },
  sendArea: {
    width: '100% !important',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  strong: {
    fontWeight: 600,
  },
  targetShow: {
    padding: '10px 0',
  },
  confirmationModal: {
    padding: 20,
  },
  w100: {
    width: '100%',
  },
  cancelButton: {
    backgroundColor: '#EB5757',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#cc4b4b',
    },
  },
  createButton: {
    marginLeft: 20,
  },
}));
