import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  radioRow: {
    display: ' flex',
    flex: 1,
    alignItems: 'center',
  },
  radioLabel: {
    whiteSpace: 'nowrap',
    minWidth: 300,
  },
  radioMultiSelect: {},
});
