import {
  List,
  TextField,
  ShowButton,
  Title,
  CreateButton,
  EditButton,
  ChipField,
} from 'react-admin';
import { Link } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Datagrid from 'components/Datagrid';
import Breadcrumb from 'components/Breadcrumb';
import { FC } from 'react';

const SubscribersCountField = (props: any) => {
  const { record } = props;
  return (
    <ChipField
      record={{ id: 0, total: record?.activeSubscribers }}
      source="total"
    />
  );
};

const CreateSubscriptionButton = () => (
  <CreateButton
    component={Link}
    to={{ pathname: `/subscription/create` }}
    label="Add new subscription"
  />
);

const ListEmpty: FC<any> = props => (
  <Box textAlign="center" m={1}>
    <Typography>No subscriptions have been added yet.</Typography>
    <CreateSubscriptionButton {...props} />
  </Box>
);

export const SubscriptionsList: FC<any> = props => (
  <Card {...props}>
    <Title title="Subscriptions" />
    <CardContent {...props}>
      <Breadcrumb routes={[{ text: 'Subscriptions' }]} />
      <List
        {...props}
        empty={<ListEmpty />}
        bulkActionButtons={false}
        exporter={false}
        sort={{ field: 'name', order: 'ASC' }}
        resource="subscription-report"
      >
        <Datagrid>
          <TextField source="name" label="Name" />
          <SubscribersCountField label="Active Subscribers" />
          <EditButton />
          <ShowButton />
        </Datagrid>
      </List>
    </CardContent>
  </Card>
);
