import Rating from '@material-ui/lab/Rating';

export const RatingField = ({
  record,
  source,
}: {
  record?: [];
  source: any;
}) => {
  const value = record && record[source] ? record[source] : 0;

  return (
    <Rating name="half-rating" disabled={true} value={value} precision={0.5} />
  );
};
