import { Fragment } from 'react';
import {
  Edit,
  TextInput,
  BooleanInput,
  DeleteButton,
  useGetIdentity,
} from 'react-admin';
import Box from '@material-ui/core/Box';
import { useStyles } from './useStyles';
import { validateUserCreation } from './userValidation';
import SimpleForm from 'components/SimpleForm';
import { UserHeight } from '../components/user-height';

const CustomDelete = (props: any) => {
  const { identity, loading: identityLoading } = useGetIdentity();

  const classes = useStyles();
  const user = props.record;

  return (
    <Fragment>
      {!identityLoading && identity?.id != user.id ? (
        <DeleteButton
          {...props}
          confirmTitle={`Are you sure you want to delete this user and all associated data?`}
          confirmContent={
            <p>
              <p>
                <strong>This action cannot be undone.</strong>
              </p>
              <br />
              Please also remember to delete the user&apos;s Shopify account by
              following these steps: <br />
              1. From your Shopify admin, click <strong>Customers</strong>.
              <br />
              2. Click the name of the customer you want to request an erasure
              for.
              <br />
              3. Click <strong>Erase personal data</strong>.
            </p>
          }
          mutationMode={'optimistic'}
          className={classes.deleteButton}
        />
      ) : (
        <Fragment />
      )}
    </Fragment>
  );
};

export const UserEdit = (props: any) => {
  const classes = useStyles();

  return (
    <Edit {...props} mutationMode={'optimistic'} className={classes.createBox}>
      <SimpleForm validate={validateUserCreation}>
        <CustomDelete />
        <Box className={classes.fields}>
          <TextInput className={classes.input} fullWidth source="firstName" />
          <TextInput className={classes.input} fullWidth source="lastName" />
        </Box>
        <Box display="flex" className={classes.fields}>
          <TextInput
            className={classes.input}
            type="email"
            disabled={true}
            source="email"
          />
          <TextInput
            className={classes.input}
            type="date"
            source="dateOfBirth"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <UserHeight source="heightInches" />
        </Box>
        <BooleanInput source="active" className={classes.input} />
      </SimpleForm>
    </Edit>
  );
};
