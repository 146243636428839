import { FC, Fragment, useState, useEffect } from 'react';
import {
  TextInput,
  SelectInput,
  FormDataConsumer,
  SaveButton,
  Button,
  SimpleForm,
  useGetList,
} from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { useForm, useFormState } from 'react-final-form';
import { dataProvider } from 'providers/dataProvider';
import Box from '@material-ui/core/Box';
import { useNotificationsStyles } from '../views/useNotificationsStyles';
import SendIcon from '@material-ui/icons/Send';
import Toolbar from '../../../components/Toolbar';
import ConfirmationModal from '../components/notifications-create-confirmation-modal';
import RadioButtonsGroup from 'components/RadioGroup';
import Target from './Target';

const interactionTypechoices = [
  { value: 'AppScreen', label: 'App' },
  { value: 'ExternalURL', label: 'URL' },
  { value: 'Highlights', label: 'Highlight' },
];

const CustomInteractionRadioButtons = ({ formData }: any) => {
  const form = useForm();

  const handleInteractionChange = (value: string) => {
    form.change('interactionType', value);
  };

  return (
    <RadioButtonsGroup
      initialValue={interactionTypechoices[0].value}
      value={formData?.interactionType}
      choices={interactionTypechoices}
      onChange={handleInteractionChange}
    />
  );
};

const CustomSaveButton = (props: any) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <FormDataConsumer>
      {({ formData }) => {
        const titleLength = formData.title?.length || 0;
        const messageLength = formData.message?.length || 0;

        const challengeMissing =
          formData.type === 'segmentedByChallenge' &&
          !formData.challenges?.length;

        const subscriptionMissing =
          formData.type === 'segmentedBySubscription' &&
          !formData.subscriptions?.length;

        const sendDisabled =
          !formData.title ||
          !formData.message ||
          !formData.type ||
          challengeMissing ||
          subscriptionMissing;

        const finalSendDisabled =
          sendDisabled ||
          !formData.pushNotificationPassword ||
          formData.pushNotificationPassword?.length < 8;

        return (
          <>
            <ConfirmationModal
              open={open}
              handleOpen={handleOpen}
              handleClose={handleClose}
              record={formData}
              challenges={props.challenges}
              subscriptions={props.subscriptions}
            >
              <SaveButton
                label="SEND"
                icon={<></>}
                endIcon={<SendIcon />}
                disabled={finalSendDisabled}
                redirect="list"
                {...props}
              />
            </ConfirmationModal>
            <Typography style={{ fontSize: 16, marginRight: 12 }}>
              {titleLength + messageLength} / 178
            </Typography>
            <Button
              variant="contained"
              onClick={handleOpen}
              label="SEND"
              disabled={sendDisabled}
              redirect="list"
              alignIcon="right"
              {...props}
            >
              <SendIcon />
            </Button>
          </>
        );
      }}
    </FormDataConsumer>
  );
};

const Interaction = () => {
  const classes = useNotificationsStyles();
  const form = useForm();

  const formState = useFormState();

  const appOptions = [
    { name: 'Push Notification', value: 'PushNotification' },
    { name: 'Highlights - Resources', value: 'HighlightsResources' },
    { name: 'Highlights - Programs', value: 'HighlightsPrograms' },
    { name: 'Workouts', value: 'Workouts' },
  ];

  const [highlights, setHighlights] = useState<any[]>([]);

  const { getHighlights } = dataProvider;

  const getHighlightsFromApi = async () => {
    try {
      const highlightsRes = await getHighlights();
      if (highlightsRes?.data && Array.isArray(highlightsRes.data)) {
        setHighlights(
          highlightsRes.data.sort((a: any, b: any) =>
            a.title.localeCompare(b.title),
          ),
        );
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    getHighlightsFromApi();
  }, []);

  const interactionType = formState.values.interactionType;

  useEffect(() => {
    form.change('interactionValue', '');
  }, [interactionType]);

  const onChangeHighlight: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = event => {
    const id = event.target.value;
    const blogType = highlights.find(hl => hl.id === id)?.blogType;
    form.change(
      'interactionScreen',
      blogType === 'highlights' ? 'HighlightsResources' : 'HighlightsPrograms',
    );
  };

  return (
    <Box className={classes.fields} flex={1}>
      <FormDataConsumer>
        {({ formData, ...rest }) => {
          return (
            <Fragment>
              {formData.interactionType === 'AppScreen' && (
                <SelectInput
                  {...rest}
                  choices={appOptions}
                  label="App"
                  source="interactionScreen"
                  optionText="name"
                  optionValue="value"
                  fullWidth
                  defaultValue={appOptions[0].value}
                />
              )}
              {formData.interactionType === 'ExternalURL' && (
                <TextInput
                  {...rest}
                  source="interactionValue"
                  type="string"
                  label="URL"
                  fullWidth
                />
              )}
              {formData.interactionType === 'Highlights' && (
                <SelectInput
                  {...rest}
                  choices={highlights}
                  label="Highlight"
                  source="interactionValue"
                  optionText="title"
                  optionValue="id"
                  fullWidth
                  onChange={onChangeHighlight}
                />
              )}
            </Fragment>
          );
        }}
      </FormDataConsumer>
    </Box>
  );
};

export const NotificationsForm: FC<any> = props => {
  const classes = useNotificationsStyles();

  const { data: challenges } = useGetList(
    'challenge-simple',
    {
      page: 1,
      perPage: 1000,
    },
    {
      field: 'name',
      order: 'ASC',
    },
    { active: true },
  );

  const { data: subscriptions } = useGetList(
    'subscription-simple',
    {
      page: 1,
      perPage: 1000,
    },
    {
      field: 'name',
      order: 'ASC',
    },
    { active: true },
  );

  return (
    <SimpleForm
      {...props}
      toolbar={
        <Toolbar
          customSaveButton={
            <CustomSaveButton
              challenges={challenges}
              subscriptions={subscriptions}
            />
          }
        />
      }
      initialValues={{
        type: 'all',
        interactionType: interactionTypechoices[0].value,
      }}
    >
      <Target />

      <Typography className={classes.labels}>Interaction</Typography>

      <Box className={`${classes.fields} ${classes.marginTop}`} flex={1}>
        <FormDataConsumer>
          {({ formData }) => (
            <CustomInteractionRadioButtons formData={formData} />
          )}
        </FormDataConsumer>
      </Box>

      <Interaction />

      <Typography className={classes.labels}>Title</Typography>

      <Box
        className={`${classes.fields} ${classes.marginTop}`}
        alignItems="center"
      >
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            const messageLength = formData.message?.length || 0;
            const remainingLength = 178 - messageLength;
            const maxTitleLength = remainingLength > 65 ? 65 : remainingLength;

            return (
              <TextInput
                {...rest}
                source="title"
                type="string"
                label="Title"
                fullWidth
                InputProps={{ inputProps: { maxLength: maxTitleLength } }}
              />
            );
          }}
        </FormDataConsumer>
      </Box>

      <Typography className={classes.labels}>Message</Typography>

      <Box
        className={`${classes.fields} ${classes.marginTop}`}
        alignItems="center"
      >
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            const titleLength = formData.title?.length || 0;
            return (
              <TextInput
                {...rest}
                source="message"
                label="Message"
                type="string"
                fullWidth
                multiline
                rows={3}
                InputLabelProps={{ shrink: true }}
                InputProps={{ inputProps: { maxLength: 178 - titleLength } }}
              />
            );
          }}
        </FormDataConsumer>
      </Box>
    </SimpleForm>
  );
};
