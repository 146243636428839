import { WEEK_DAYS } from 'constants/constants';
import React from 'react';
import { TextField } from 'react-admin';

export const DayOfWeekField: React.FC<any> = props => {
  const { record } = props;
  const weekDay = WEEK_DAYS.find(weekDay => {
    return weekDay.id == record.weekDay;
  });

  if (!weekDay) return <React.Fragment />;

  return (
    <TextField
      record={{ id: weekDay.id, weekDay: weekDay.day }}
      source="weekDay"
      addLabel={true}
      label="Day of Week"
    />
  );
};

export default DayOfWeekField;
