import * as React from 'react';
import { AppBar, AppBarProps, Layout } from 'react-admin';
import { AppLocationContext } from '@react-admin/ra-navigation';
import ImageLogo from 'assets/logo.webp';
import { useStyles } from './useStyles';
import Menu from './Menu';

const CustomAppBar: React.FC = (props: AppBarProps) => {
  const classes = useStyles();
  return (
    <AppBar className={classes.appBar} {...props}>
      <div className={classes.navbarRoot}>
        <img className={classes.logo} src={ImageLogo} />
      </div>
    </AppBar>
  );
};

const CustomLayout: React.FC = (props: AppBarProps) => (
  <AppLocationContext>
    <Layout {...props} appBar={CustomAppBar} menu={Menu}>
      {props.children}
    </Layout>
  </AppLocationContext>
);

export default CustomLayout;
