import {
  Create,
  TextInput,
  PasswordInput,
} from 'react-admin';
import Box from '@material-ui/core/Box';
import { validateUserCreation } from './userValidation';
import { useStyles } from './useStyles';
import SimpleForm from 'components/SimpleForm';
import { UserHeight } from '../components/user-height';

export const UserCreate = (props: any) => {
  const classes = useStyles();
  return (
    <Create {...props} className={classes.createBox}>
      <SimpleForm validate={validateUserCreation}>
        <Box className={classes.fields}>
          <TextInput fullWidth source="firstName" className={classes.input} />
          <TextInput fullWidth source="lastName" className={classes.input} />
        </Box>
        <Box className={classes.fields}>
          <TextInput
            source="dateOfBirth"
            InputLabelProps={{
              shrink: true,
            }}
            type="date"
            className={classes.input}
          />
          <UserHeight source="heightInches" />
        </Box>
        <Box className={classes.fields}>
          <TextInput
            fullWidth
            source="email"
            type="email"
            className={classes.input}
            inputProps={{ autocomplete: 'username' }}
          />
          <PasswordInput
            fullWidth
            source="password"
            className={classes.input}
            inputProps={{ autocomplete: 'current-password' }}
          />
        </Box>
      </SimpleForm>
    </Create>
  );
};
