interface Ierrors {
  title?: string;
  shopifyPageOverviewRef?: string;
}

export const validateOverviewCreation = (values: any) => {
  const errors: Ierrors = {};

  if (!values.title) {
    errors.title = 'The field Section Title is required';
  } else if (values.title.length > 60) {
    errors.title = 'Title must not exceed the limit of 60 characters';
  }

  if (!values.shopifyPageOverviewRef) {
    errors.shopifyPageOverviewRef = 'The field Shopify Page ID is required';
  }

  return errors;
};
