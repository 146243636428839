interface Ierrors {
  title?: string;
  weekDay?: string;
  location?: string;
  weight?: string;
  setCount?: string;
  repCount?: string;
  restSeconds?: string;
  exerciseLibraryId?: string;
}

export const validateExerciseCreation = (isEdit = false) => (values: any) => {
  const errors: Ierrors = {};

  if (!values.title) {
    errors.title = 'Title is required.';
  }

  if (!isEdit) {
    if (!values.exerciseLibraryId) {
      errors.exerciseLibraryId =
        'No exercise found! Please check the Title and try again.';
    }
  }

  if (!values.weekDay) {
    errors.weekDay = 'Day of Week is required.';
  } else if (!(values.weekDay > 0 && values.weekDay <= 7)) {
    errors.weekDay = 'Day of Week must be between 1 and 7.';
  }

  if (!values.location) {
    errors.location = 'Location is required.';
  }

  if (values.weight?.length > 11) {
    errors.weight = 'Weight must be less than or equal to 11 characters.';
  }

  if (values.setCount > 100) {
    errors.setCount = 'SetCount must be less than 100.';
  }

  if (values.repCount > 100) {
    errors.repCount = 'RepCount must be less than 100.';
  }

  if (values.restSeconds?.length > 10) {
    errors.restSeconds =
      'RestSeconds must be less than or equal to 10 characters.';
  }

  return errors;
};
