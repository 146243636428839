import { FC } from 'react';
import { Create } from 'react-admin';
import { validateNotificationsCreation } from './notifications-validation';
import { useNotificationsStyles } from './useNotificationsStyles';
import { NotificationsForm } from '../components/notifications-form';
import Breadcrumb from 'components/Breadcrumb';
import cloneDeep from 'lodash/cloneDeep';

export const NotificationsCreate: FC<any> = props => {
  const classes = useNotificationsStyles();

  const transformFormData = (data: any) => {
    const parsedData = cloneDeep(data);

    const challenges = data?.challenges?.map((challenge: string) => ({
      id: challenge,
    }));
    parsedData.challenges = challenges;

    const subscriptions = data?.subscriptions?.map((subscription: string) => ({
      id: subscription,
    }));
    parsedData.subscriptions = subscriptions;

    if (parsedData.interactionType === 'ExternalURL') {
      delete parsedData.interactionScreen;
    }

    if (parsedData.type === 'segmentedByChallenge') {
      delete parsedData.subscriptions;
    }

    if (parsedData.type === 'segmentedBySubscription') {
      delete parsedData.challenges;
    }

    return parsedData;
  };

  return (
    <>
      <Breadcrumb
        routes={[
          { to: `/#/notification`, text: 'Notifications' },
          { text: 'Send Notification' },
        ]}
      />
      <Create
        {...props}
        mutationMode="pessimistic"
        className={classes.createBox}
        transform={transformFormData}
        successMessage="Notification sent successfully"
      >
        <NotificationsForm
          {...props}
          validate={validateNotificationsCreation}
        />
      </Create>
    </>
  );
};
