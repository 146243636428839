import React from 'react';

import { useProgressStyles } from './styles';

interface ProgressBarProps {
  /**
   * Number between 0 and 1
   */
  progress?: number;
}

const formatProgress = (progress: number) => {
  if (progress > 1) {
    return '100%';
  }

  if (progress < 0) {
    return '0%';
  }

  const formattedProgress = (progress * 100).toLocaleString(undefined, {
    maximumFractionDigits: 2,
  });

  return `${formattedProgress}%`;
};

const getProgressColor = (progress: number) => {
  if (progress < 0.33) {
    return '#EB5757';
  }

  if (progress < 1) {
    return '#F2C94C';
  }

  return '#27AE60';
};

export const ProgressBar: React.FC<ProgressBarProps> = ({ progress = 0 }) => {
  const classes = useProgressStyles();

  const formattedProgress = formatProgress(progress);
  const progressColor = getProgressColor(progress);

  return (
    <div className={classes.container}>
      <div
        className={classes.progress}
        style={{ width: formattedProgress, backgroundColor: progressColor }}
      />
      <span className={classes.text}>{formattedProgress}</span>
    </div>
  );
};

export default ProgressBar;
