import { makeStyles } from '@material-ui/core/styles';

export const useExerciseStyles = makeStyles({
  fields: {
    width: '100% !important',
    display: 'flex',
    justifyContent: 'space-between',
  },
  createBox: {
    maxWidth: '100%',
  },
  content: {
    maxWidth: '100%',
  },
  halfWidth: {
    width: '50%',
    margin: '0px 15px',
  },
  table: {
    width: '30%',
    minWidth: '400px',
    border: '1px solid rgb(224, 224, 224)',
    '& th': {
      padding: 5,
    },
    '& td': {
      padding: 5,
      textAlign: 'left',
      borderLeft: '1px solid rgb(224, 224, 224)',
    },
    '& button': {
      height: 20,
      marginLeft: 30,
      textAlign: 'right',
      borderLeft: '1px solid rgb(224, 224, 224)',
    },
  },
  actionButtons: {
    '& > *': {
      marginRight: '30px',
    },
  },
  marginRight: {
    marginRight: 15,
  },
  autoCompleteFullWidth: {
    display: 'flex',
    flex: 1,
  },
  libraryLinkText: {
    opacity: 0.4,
    textAlign: 'left',
    fontSize: '14px',
  },
  libraryLinkIcon: {
    opacity: 0.4,
    fontSize: '20px',
    marginRight: '4px',
  },
  divider: {
    backgroundColor: '#f5f5f5',
    width: '100%',
    height: '2px',
    margin: '8px 0px 20px',
  },
});
