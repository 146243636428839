import { makeStyles } from '@material-ui/core/styles';

export const useChallengeStyles = makeStyles({
  fields: {
    width: '100% !important',
    display: 'flex',
    justifyContent: 'space-between',
  },
  createBox: {
    maxWidth: '100%',
  },
  content: {
    maxWidth: '100%',
  },
  halfWidth: {
    width: '50%',
    margin: '0px 15px',
  },
  table: {
    width: '30%',
    minWidth: '400px',
    border: '1px solid rgb(224, 224, 224)',
    '& th': {
      padding: 5,
    },
    '& td': {
      padding: 5,
      textAlign: 'left',
      borderLeft: '1px solid rgb(224, 224, 224)',
    },
    '& button': {
      height: 20,
      marginLeft: 30,
      textAlign: 'right',
      borderLeft: '1px solid rgb(224, 224, 224)',
    },
  },
  actionButtons: {
    '& > *': {
      marginRight: '30px',
    },
  },
  headerImage: {
    paddingBottom: 20,
    height: 150,
    maxWidth: 300,
  },
  participantButton: {
    padding: '0px 4px',
    fontSize: '12px',
    float: 'right',
  },
  breadcrumb: {
    display: 'flex',
    margin: '32px 0 16px',
    gap: 8,
    '& > p': {
      fontSize: '1.25rem',
      lineHeight: '20px',
      fontWeight: 700,
      '& > a': {
        color: 'black',
      },
    },
  },
  submissionContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 8,
  },
  fullWidth: {
    width: '100%',
  },
  containedInput: {
    maxWidth: '256px',
  },
});
