import React from 'react';
import { Resource, Admin } from 'react-admin';
import UserModule from 'modules/users';
import ExercisesLibrary from 'modules/exercisesLibrary';
import ChallengeModule from 'modules/challenges';
import ChallengeOverviewsModule from 'modules/challengeOverviews';
import ChallengeProgressModule from 'modules/challengeProgress';
import ChallengeCommentsModule from 'modules/challengeComments';
import ChallengeWeekModule from 'modules/challengeWeeks';
import ExerciseModule from 'modules/exercises';
import DeviceModule from 'modules/devices';
import CustomLayout from 'customizations/CustomLayout';
import Notifications from 'modules/notifications';
import Subscriptions from 'modules/subscriptions';
import Login from 'modules/login';
import userSubscriptions from 'modules/userSubscriptions';
import dataProvider from 'providers/dataProvider';
import authProvider from 'providers/authProvider';
import customRoutes from './routes';

const App: React.FC = () => (
  <Admin
    layout={CustomLayout}
    dataProvider={dataProvider}
    loginPage={Login}
    authProvider={authProvider}
    customRoutes={customRoutes}
  >
    <Resource {...ExercisesLibrary} />
    <Resource {...UserModule} />
    <Resource {...ChallengeModule} />
    <Resource {...ChallengeOverviewsModule} />
    <Resource {...ChallengeProgressModule} />
    <Resource {...ChallengeCommentsModule} />
    <Resource {...ChallengeWeekModule} />
    <Resource {...ExerciseModule} />
    <Resource {...DeviceModule} />
    <Resource {...Notifications} />
    <Resource {...Subscriptions} />
    <Resource name="user-role" />
    <Resource name="user-challenge" />
    <Resource name="subscription-challenge" />
    <Resource name="challenge-simple" />
    <Resource name="subscription-simple" />
    <Resource {...userSubscriptions} />
    <Resource name="shopify-content" />
    <Resource name="subscription-report" />
    <Resource name="muscle-group" />
    <Resource name="equipment" />
  </Admin>
);

export default App;
