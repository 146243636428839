import { makeStyles } from '@material-ui/core/styles';

export const useChallengeStyles = makeStyles({
  createBox: {
    maxWidth: '100%',
  },
  table: {
    '& .column-title': {
      width: '75%',
    },
    '& .column-sortPriority': {
      width: '25%',
    },
  },
  viewTable: {
    minWidth: '400px',
    border: '1px solid rgb(224, 224, 224)',
    '& th': {
      padding: 5,
    },
    '& td': {
      padding: 5,
      textAlign: 'left',
      borderLeft: '1px solid rgb(224, 224, 224)',
    },
  },
  content: {
    maxWidth: '100%',
  },
});
