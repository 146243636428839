import React, { FC } from 'react';
import { ExportButton, sanitizeListRestProps, TopToolbar } from 'react-admin';
import { Box } from '@material-ui/core';

export const CustomListTopToolbar: FC<any> = ({
  currentSort,
  className,
  resource,
  filters,
  displayedFilters,
  exporter,
  filterValues,
  //permanentFilter,
  showFilter,
  maxResults,
  total,
  hasCreate,
  createButton,
  hasCustomExporter,
  exportButton,
  children,
  ...rest
}) => {
  const hasExporter = exporter !== false;
  const hasCustomExporterButton = !!hasCustomExporter && !!exportButton;

  return (
    <Box pl={2} pr={2}>
      <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
        {filters &&
          React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
          })}
        {hasCreate && createButton}
        {children}
        {!hasCustomExporterButton && hasExporter && (
          <ExportButton
            disabled={total === 0}
            resource={resource}
            sort={currentSort}
            filterValues={filterValues}
            exporter={exporter}
            maxResults={maxResults}
          />
        )}
        {hasCustomExporterButton && exportButton}
      </TopToolbar>
    </Box>
  );
};
