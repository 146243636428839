import { Edit } from 'react-admin';
import { useChallengeProgressStyles } from './useChallengeProgressStyles';
import { ChallengeProgressForm } from '../components/challenge-progress-edit-form';

export const ChallengeProgressEdit = (props: any) => {
  const classes = useChallengeProgressStyles();

  return (
    <Edit
      {...props}
      basePath="/user-challenge"
      resource="user-challenge"
      mutationMode="undoable"
      hasShow={false}
      className={classes.createBox}
    >
      <ChallengeProgressForm {...props} />
    </Edit>
  );
};
