interface Ierrors {
  title?: string;
  setCount?: string;
  repCount?: string;
}

export const validateLibraryExerciseCreation = (values: any) => {
  const errors: Ierrors = {};

  if (!values.title) {
    errors.title = 'Title is required.';
  }
  if (values.setCount > 100) {
    errors.setCount = 'Max is 100.';
  }
  if (values.repCount > 100) {
    errors.repCount = 'Max is 100.';
  }

  return errors;
};
