import { FC } from 'react';
import { TextInput, BooleanInput } from 'react-admin';
import SimpleForm from 'components/SimpleForm';
import Typography from '@material-ui/core/Typography';
import { useSubscriptionsStyles } from '../views/useSubscriptionsStyles';

export const SubscriptionsForm: FC<any> = props => {
  const classes = useSubscriptionsStyles();

  return (
    <SimpleForm {...props}>
      <TextInput
        source="name"
        label="Name"
        type="string"
        fullWidth
        InputProps={{ inputProps: { maxLength: 100 } }}
      />

      <TextInput
        source="shopifyProductRef"
        label="Shopify Product Id"
        type="string"
        fullWidth
        InputProps={{ inputProps: { maxLength: 20 } }}
      />

      <TextInput
        source="duration"
        label="Duration"
        type="number"
        fullWidth
        InputProps={{ inputProps: { maxLength: 20 } }}
      />

      <BooleanInput
        source="active"
        label="Active"
        fullWidth
        defaultValue={false}
      />

      <Typography className={classes.labels}>Additional Features</Typography>

      <BooleanInput
        source="workoutGenerator"
        label="Workout Generator"
        fullWidth
        defaultValue={false}
      />
    </SimpleForm>
  );
};
