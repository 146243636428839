interface Ierrors {
  name?: string;
  description?: string;
  content?: string;
  fbGroupUrl?: string;
  active?: string;
  startDate?: string;
  endDate?: string;
}

export const validateChallengeCreation = (values: any) => {
  const errors: Ierrors = {};

  if (!values.name) {
    errors.name = 'The field Name is required';
  }

  if (!values.description) {
    errors.description = 'The field Description is required';
  }

  if (!values.isGroupChat) {
    if (!values.fbGroupUrl) {
      errors.fbGroupUrl = 'The field Facebook Group Url is required';
    }
  }

  if (!values.startDate) {
    errors.startDate = 'The field Start date is required';
  }

  if (!values.endDate) {
    errors.endDate = 'The field End date is required';
  }

  return errors;
};
