interface Ierrors {
  title?: string;
  message?: string;
  challenges?: string;
  subscriptions?: string;
  interactionValue?: string;
}

const isValidURL = (str: string) => {
  try {
    const url = new URL(str);
    return url.protocol === 'http:' || url.protocol === 'https:';
  } catch (error) {
    return false;
  }
};

export const validateNotificationsCreation = (values: any) => {
  const errors: Ierrors = {};

  if (!values.title) {
    errors.title = 'Title is required.';
  }

  if (values.title?.length > 65) {
    errors.title = "Title's maximum length is 65 characters.";
  }

  if (values.title?.length + values.message?.length > 178) {
    errors.title = 'Maximum length for title and message is 178 characters.';
    errors.message = 'Maximum length for title and message is 178 characters.';
  }

  if (values.type === 'segmentedByChallenge' && !values.challenges?.length) {
    errors.challenges = 'You must select at least one challenge.';
  }

  if (
    values.type === 'segmentedBySubscription' &&
    !values.subscriptions?.length
  ) {
    errors.subscriptions = 'You must select at least one subscription.';
  }

  if (values.interactionType === 'ExternalURL') {
    if (!values.interactionValue) {
      errors.interactionValue = 'Please enter a valid URL';
    }

    if (values.interactionValue && !isValidURL(values.interactionValue)) {
      errors.interactionValue = 'Please enter a valid URL';
    }
  }

  if (values.interactionType === 'Highlights' && !values.interactionValue) {
    errors.interactionValue =
      'Please select a Highlight from the dropdown menu';
  }

  return errors;
};
