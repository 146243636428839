import React, { FC, useEffect, useState } from 'react';
import { Edit } from 'react-admin';

import Breadcrumb from 'components/Breadcrumb';
import { ExerciseForm } from '../components/exercises-form';
import { getChallenge, getChallengeWeek } from 'services/challenge';
import { validateExerciseCreation } from './exerciseValidation';
import { useExerciseStyles } from './useExerciseStyles';

export const ExerciseEdit: FC<any> = props => {
  const [week, setWeek] = useState('Week');
  const [challenge, setChallenge] = useState('Challenge');
  const classes = useExerciseStyles();

  const urlParams = new URLSearchParams(props.location.search);
  const challengeId = urlParams.get('challengeId') || '';
  const weekId = urlParams.get('challengeWeekId') || '';

  useEffect(() => {
    const loadData = async () => {
      try {
        const [
          { data: challengeData },
          { data: challengeWeekData },
        ] = await Promise.all([
          getChallenge(challengeId),
          getChallengeWeek(weekId),
        ]);

        setChallenge(challengeData?.name || 'Challenge');
        setWeek(`Week ${challengeWeekData?.weekNumber || ''}`);
      } catch (error) {
        console.error(error);
      }
    };

    loadData();
  }, []);

  return (
    <>
      <Breadcrumb
        routes={[
          { text: 'Challenges', to: '/#/challenge' },
          { text: challenge, to: `/#/challenge/${challengeId}/show` },
          {
            text: week,
            to: `/#/challenge/${challengeId}/week/${weekId}/exercises`,
          },
          { text: 'Edit Exercise' },
        ]}
      />
      <Edit {...props} undoable={false} className={classes.createBox}>
        <ExerciseForm {...props} validate={validateExerciseCreation(true)} />
      </Edit>
    </>
  );
};
