import { AxiosResponse } from 'axios';
import { axiosInstance } from 'services/http';

export interface Exercise {
  id: string;
  challengeWeekId: string;
  title: string;
  comments: string;
  location: string;
  repCount: number;
  setCount: number;
  restSeconds: string;
  sortPriority: number;
  videoUrl: string;
  weekDay: number;
  weight: string;
  createdAt: string;
  updatedAt: string;
}

export interface Week {
  id: string;
  challengeId: string;
  weekNumber: number;
  sortPriority: number;
  exercises: Exercise[];
  createdAt: string;
  updatedAt: string;
}

export interface Challenge {
  id: string;
  name: string;
  description: string;
  active: boolean;
  startDate: string;
  endDate: string;
  challengeWeeks: Week[];
  createdAt: string;
  updatedAt: string;
}

export interface PaginatedData<T> {
  count: number;
  page: number;
  pageCount: number;
  total: number;
  data: T;
}

export const getChallenge = async (
  id: string,
): Promise<AxiosResponse<Challenge>> => {
  return axiosInstance.get(`/challenge/${id}`);
};

export const getChallengeWeek = async (
  id: string,
): Promise<AxiosResponse<Week>> => {
  return axiosInstance.get(`/challenge-week/${id}`);
};

export const getChallengeExercises = async (
  id: string,
): Promise<AxiosResponse<string>> => {
  const url = `challenge/${id}/challenge-week/export`;

  return axiosInstance.get(url);
};

export const getChallengeExercisesTemplate = async (): Promise<
  AxiosResponse<string>
> => {
  const url = `challenge/challenge-week/template/export`;

  return axiosInstance.get(url);
};
