import { WEEK_DAYS } from 'constants/constants';
import React from 'react';
import { SelectInput } from 'react-admin';

export const DayOfWeekSelect: React.FC<any> = () => {
  return (
    <SelectInput
      label="Day of Week"
      source="weekDay"
      choices={WEEK_DAYS.map(weekDay => {
        return { id: weekDay.id, name: weekDay.day };
      })}
    />
  );
};

export default DayOfWeekSelect;
