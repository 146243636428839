import * as React from 'react';
import {
  linkToRecord,
  Record,
  ReferenceManyField,
  Show,
  TextField,
  DateField,
} from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import { useChallengeStyles } from './useChallengeStyles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CheckIcon from '@material-ui/icons/Check';
import { Link } from 'react-router-dom';
import BottomToolbar from '../../../components/Toolbar';
import RedirectButton from 'components/redirect-button';

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const MemberCountField = (props: { total?: number }) => (
  <TextField record={{ id: 0, total: props.total }} source="total" />
);

const ChallengeWeekButton = ({ id }: { id: string }) => {
  return (
    <Button
      variant="contained"
      component={Link}
      to={{ pathname: `/challenge/${id}/weeks` }}
    >
      Edit Workout Plan
    </Button>
  );
};

const ViewProgressButton = ({ record }: { record: Record }) => {
  return (
    <Button
      variant="contained"
      component={Link}
      to={{ pathname: `/challenge/${record.id}/progress` }}
    >
      View User Progress
    </Button>
  );
};

const ViewCommentsButton = ({ record }: { record: Record }) => {
  return (
    <Button
      variant="contained"
      component={Link}
      to={{
        pathname: `${linkToRecord(
          '/challenge-comment-report',
          record.id,
        )}/show`,
      }}
    >
      Comments
    </Button>
  );
};

const ViewOverviewButton = ({ record }: { record: Record }) => {
  return (
    <Button
      variant="contained"
      component={Link}
      to={{ pathname: `${linkToRecord('/challenge', record.id)}/sections` }}
    >
      Overview
    </Button>
  );
};

const ShowChallenge = (props: any) => {
  const { record } = props;

  const classes = useChallengeStyles();

  return (
    <Box>
      <Box
        display="flex"
        flexDirection="column"
        m={3}
        className={classes.content}
      >
        <Box mb={3}>{record.name}</Box>
        <Box mb={3}>{record.description}</Box>
        <Box>
          <Table className={classes.table} aria-label="customized table">
            <TableBody>
              <StyledTableRow key="participants">
                <StyledTableCell component="th" scope="row">
                  Participants
                </StyledTableCell>
                <StyledTableCell align="right">
                  <ReferenceManyField
                    basePath={props.basePath}
                    record={record}
                    label="Participants"
                    reference="user-challenge"
                    target="challengeId"
                  >
                    <MemberCountField />
                  </ReferenceManyField>
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow key="startDate">
                <StyledTableCell component="th" scope="row">
                  Start Date
                </StyledTableCell>
                <StyledTableCell align="right">
                  <DateField
                    source="startDate"
                    showTime
                    options={{
                      day: '2-digit',
                      month: '2-digit',
                      year: 'numeric',
                      timeZone: 'US/Eastern',
                    }}
                    emptyText="-"
                  />
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow key="endDate">
                <StyledTableCell component="th" scope="row">
                  End Date
                </StyledTableCell>
                <StyledTableCell align="right">
                  <DateField
                    source="endDate"
                    showTime
                    options={{
                      day: '2-digit',
                      month: '2-digit',
                      year: 'numeric',
                      timeZone: 'US/Eastern',
                    }}
                    emptyText="-"
                  />
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow key="active">
                <StyledTableCell component="th" scope="row">
                  Active
                </StyledTableCell>
                <StyledTableCell align="right">
                  {record.active ? <CheckIcon /> : <React.Fragment />}
                </StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </Box>
        <Box
          display="flex"
          mt={3}
          flexDirection="row"
          className={classes.actionButtons}
        >
          <ChallengeWeekButton id={record.id} />
          <ViewProgressButton record={record} />
          <ViewCommentsButton record={record} />
          <ViewOverviewButton record={record} />
        </Box>
      </Box>
      <BottomToolbar
        hasSaveButton={false}
        backButton={
          <RedirectButton
            label="Go Back"
            redirectTo={{ pathname: `/challenge` }}
          />
        }
      />
    </Box>
  );
};

export const ChallengeView = (props: any) => {
  const classes = useChallengeStyles();

  return (
    <Show {...props} className={classes.createBox}>
      <ShowChallenge {...props} />
    </Show>
  );
};
