import { FC, useEffect } from 'react';
import { SaveButton, useNotify } from 'react-admin';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Breadcrumb from 'components/Breadcrumb';
import SwitchField from 'components/SwitchField';
import { useSettings } from './useSettings';
import BottomToolbar from 'components/Toolbar';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

export const Settings: FC<any> = () => {
  const notify = useNotify();

  const {
    showPrograms,
    setShowPrograms,
    shopifyApiKey,
    setShopifyApiKey,
    doUpdateSettings,
    updateSettingsError,
  } = useSettings();

  const handleSwitchChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setShowPrograms(evt.target.checked);
  };

  const handleSubmit = async () => {
    if (typeof showPrograms === 'boolean') {
      const res = await doUpdateSettings({ showPrograms, shopifyApiKey });
      if (res?.data) {
        notify('Settings updated successfully', 'info');
      }
    }
  };

  useEffect(() => {
    if (updateSettingsError) {
      notify(updateSettingsError, 'warning');
    }
  }, [updateSettingsError]);

  return (
    <>
      <Breadcrumb routes={[{ to: '/#/settings', text: 'App Settings' }]} />

      {typeof showPrograms === 'boolean' && (
        <Card>
          <CardContent>
            <SwitchField
              checked={showPrograms}
              onChange={handleSwitchChange}
              label='Display "Programs" tab on App'
            />

            <Box mt={4} maxWidth={500}>
              <TextField
                label="Shopify key"
                fullWidth
                onChange={e => {
                  setShopifyApiKey(e.target.value);
                }}
                value={shopifyApiKey}
              />
            </Box>
          </CardContent>

          <BottomToolbar
            customSaveButton={
              <SaveButton handleSubmitWithRedirect={handleSubmit} />
            }
          />
        </Card>
      )}
    </>
  );
};
