import React, { FC } from 'react';
import { useFormState, useForm } from 'react-final-form';
import { SelectArrayInput, FormDataConsumer, useGetList } from 'react-admin';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { useStyles } from './styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

interface TargetProps {
  source?: string;
}

interface Option {
  value: string;
  label: string;
  disabled?: boolean;
}

const Target: FC<TargetProps> = () => {
  const classes = useStyles();
  const { values } = useFormState();
  const form = useForm();

  const handleTargetChange = (
    _event: React.ChangeEvent<HTMLInputElement>,
    value: string,
  ) => {
    form.change('type', value);
    form.change('challenges', []);
    form.change('subscriptions', []);
  };

  const choices: Option[] = [
    { value: 'all', label: 'All' },
    {
      value: 'segmentedByChallenge',
      label: 'Segmented by Challenge',
    },
    {
      value: 'segmentedBySubscription',
      label: 'Segmented by Subscription',
    },
  ];

  const { data: challenges } = useGetList(
    'challenge-simple',
    {
      page: 1,
      perPage: 1000,
    },
    {
      field: 'name',
      order: 'ASC',
    },
    { active: true },
  );

  const challengesList = Object.values(challenges);

  const { data: subscriptions } = useGetList(
    'subscription-simple',
    {
      page: 1,
      perPage: 1000,
    },
    {
      field: 'name',
      order: 'ASC',
    },
    { active: true },
  );

  const subscriptionsList = Object.values(subscriptions);

  return (
    <Box display="flex" flexDirection="column" marginBottom="24px">
      <Typography style={{ fontSize: 24 }}>Target</Typography>
      <Box flex={1}>
        <FormControl component="fieldset" fullWidth>
          <RadioGroup
            defaultValue={choices[0].value}
            value={values.type}
            onChange={handleTargetChange}
          >
            <Box key="all" className={classes.radioRow}>
              <FormControlLabel
                value="all"
                control={<Radio color="primary" />}
                label="All"
                className={classes.radioLabel}
              />
            </Box>

            <Box key="segmentedByChallenge" className={classes.radioRow}>
              <FormControlLabel
                value="segmentedByChallenge"
                control={<Radio color="primary" />}
                label="Segmented by Challenge"
                className={classes.radioLabel}
              />
              <FormDataConsumer>
                {({ formData, ...rest }) => (
                  <SelectArrayInput
                    choices={challengesList}
                    label="Challenge"
                    source="challenges"
                    optionText="name"
                    optionValue="id"
                    disabled={formData.type !== 'segmentedByChallenge'}
                    fullWidth
                    className={classes.radioMultiSelect}
                    {...rest}
                  />
                )}
              </FormDataConsumer>
            </Box>

            <Box key="segmentedBySubscription" className={classes.radioRow}>
              <FormControlLabel
                value="segmentedBySubscription"
                control={<Radio color="primary" />}
                label="Segmented by Subscription"
                className={classes.radioLabel}
              />

              <FormDataConsumer>
                {({ formData, ...rest }) => (
                  <SelectArrayInput
                    choices={subscriptionsList}
                    label="Subscription"
                    source="subscriptions"
                    optionText="name"
                    optionValue="id"
                    disabled={formData.type !== 'segmentedBySubscription'}
                    fullWidth
                    className={classes.radioMultiSelect}
                    {...rest}
                  />
                )}
              </FormDataConsumer>
            </Box>
          </RadioGroup>
        </FormControl>
      </Box>
    </Box>
  );
};

export default Target;
