import {
  Show,
  Record,
  ArrayField,
  SingleFieldList,
  ChipField,
  DateField,
} from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import { useNotificationsStyles } from './useNotificationsStyles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Box from '@material-ui/core/Box';
import BottomToolbar from '../../../components/Toolbar';
import RedirectButton from 'components/redirect-button';

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const RenderChips = ({
  record,
  isSendReview,
}: {
  record: Record;
  isSendReview: boolean;
}) => {
  const isChallenge = record.type === 'segmentedByChallenge';

  const subsSource = isSendReview
    ? 'subscriptions'
    : 'notificationSubscriptions';
  const subsName = isSendReview ? 'name' : 'subscription.name';

  return (
    <ArrayField
      record={record}
      source={isChallenge ? 'challenges' : subsSource}
      sortBy="name"
    >
      <SingleFieldList>
        <ChipField source={isChallenge ? 'name' : subsName} />
      </SingleFieldList>
    </ArrayField>
  );
};

const RenderTarget = ({
  record,
  isSendReview,
}: {
  record: Record;
  isSendReview: boolean;
}) => {
  const classes = useNotificationsStyles();

  const isAll = record.type === 'all';

  return (
    <StyledTableRow key="target">
      <StyledTableCell component="th" scope="row">
        Target
      </StyledTableCell>
      <StyledTableCell align="right">
        <Box className={classes.targetShow}>
          {isAll ? (
            'All'
          ) : (
            <RenderChips record={record} isSendReview={isSendReview} />
          )}
        </Box>
      </StyledTableCell>
    </StyledTableRow>
  );
};

const RenderTargetEstimate = ({ targetDevicesCount, isEstimate }: any) => {
  const classes = useNotificationsStyles();

  return (
    <StyledTableRow key="target">
      <StyledTableCell component="th" scope="row">
        {isEstimate ? 'Target Estimate' : 'Target Total'}
      </StyledTableCell>
      <StyledTableCell align="right">
        <Box className={classes.targetShow}>
          {typeof targetDevicesCount === 'number' &&
            `~${targetDevicesCount} User${targetDevicesCount > 1 ? 's' : ''}`}
          {typeof targetDevicesCount === 'string' && targetDevicesCount}
        </Box>
      </StyledTableCell>
    </StyledTableRow>
  );
};

export const ShowNotification = (props: any) => {
  const { record, isSendReview, targetDevicesCount } = props;
  const classes = useNotificationsStyles();

  const rows = [
    { key: 'title', name: 'Title', details: record.title },
    {
      key: 'createdDate',
      name: 'Created Date',
      details: (
        <DateField
          source="createdAt"
          showTime
          options={{
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            timeZone: 'US/Eastern',
          }}
          emptyText="-"
        />
      ),
      disabled: isSendReview,
    },
    { key: 'message', name: 'Message', details: record.message },
  ];

  const renderRows = () =>
    rows.map(row => {
      if (row.disabled) return;

      return (
        <StyledTableRow key={row.key}>
          <StyledTableCell component="th" scope="row">
            {row.name}
          </StyledTableCell>
          <StyledTableCell align="right">{row.details}</StyledTableCell>
        </StyledTableRow>
      );
    });

  return (
    <Box display="flex" flexDirection="column" flex={1}>
      <Box
        display="flex"
        flexDirection="column"
        flex={1}
        m={3}
        className={classes.content}
      >
        <Box>
          <Table
            className={`${classes.table} ${isSendReview && classes.w100}`}
            aria-label="customized table"
          >
            <TableBody>
              {renderRows()}
              <RenderTarget record={record} isSendReview={isSendReview} />
              <RenderTargetEstimate
                isEstimate={isSendReview}
                targetDevicesCount={
                  record.numberOfDevicesSent ??
                  targetDevicesCount ??
                  (isSendReview ? '' : 'Sending')
                }
              />
            </TableBody>
          </Table>
        </Box>
      </Box>
      {!isSendReview && (
        <BottomToolbar
          hasSaveButton={false}
          backButton={
            <RedirectButton
              label="Go Back"
              redirectTo={{ pathname: `/notification` }}
            />
          }
        />
      )}
    </Box>
  );
};

export const NotificationView = (props: any) => {
  const classes = useNotificationsStyles();

  return (
    <Show {...props} className={classes.createBox}>
      <ShowNotification {...props} />
    </Show>
  );
};
