import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

interface Props {
  open: boolean;
  warning?: boolean;
  title: string;
  content: string;
  confirmText?: string;
  onConfirm: () => void;
  onClose?: () => void;
}

export const ConfirmDialog = ({
  open,
  warning,
  title,
  content,
  confirmText = 'Confirm',
  onClose,
  onConfirm,
}: Props): JSX.Element => {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="xs"
    >
      {warning && <Typography className={classes.warning}>Warning!</Typography>}
      <DialogTitle id="alert-dialog-title" className={classes.title}>
        {title}
      </DialogTitle>
      {content && (
        <DialogContent className={classes.content}>
          <DialogContentText
            id="alert-dialog-description"
            className={classes.contentText}
          >
            {content}
          </DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        <Box
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          paddingX="20px"
          paddingY="20px"
        >
          <Button
            onClick={onClose}
            color="secondary"
            variant="contained"
            className={classes.closeButton}
          >
            Cancel
          </Button>
          <Button
            onClick={onConfirm}
            color="primary"
            variant="contained"
            autoFocus
            className={warning ? classes.button : undefined}
          >
            {confirmText}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles({
  warning: {
    fontSize: '40px',
    textAlign: 'center',
    color: '#e6005a',
    marginTop: 20,
  },
  button: {
    backgroundColor: '#e6005a',
    color: 'white',
    '&:hover': {
      backgroundColor: '#e6005a',
      color: 'white',
    },
  },
  closeButton: {
    backgroundColor: '#BDBDBD',
    color: 'black',
    '&:hover': {
      backgroundColor: '#BDBDBD',
      color: 'black',
    },
  },
  title: {
    fontSize: 20,
    textAlign: 'center',
  },
  content: {
    padding: 0,
  },
  contentText: {
    fontSize: 16,
    textAlign: 'center',
    color: '#EB5757',
  },
});

export default ConfirmDialog;
