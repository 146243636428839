import React from 'react';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { Button } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

const useStyles = makeStyles({
  buttonBack: {
    marginRight: 'auto',
  },
});

export const RedirectButton: React.FC<any> = props => {
  const classes = useStyles();
  const { redirectTo, label } = props;

  return (
    <Button
      component={Link}
      variant="contained"
      color="primary"
      size="medium"
      className={classes.buttonBack}
      startIcon={<ChevronLeftIcon />}
      to={redirectTo}
      label={label}
    />
  );
};

export default RedirectButton;
