import { TextField, Title, ListContextProvider, ShowButton } from 'react-admin';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Datagrid from 'components/Datagrid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { FC, useState, useCallback } from 'react';

const ListEmpty: FC = () => (
  <Box textAlign="center" m={1}>
    <Typography>
      No challenges have been added to this subscription yet.
    </Typography>
  </Box>
);

const ShowOverviewButton = ({ record }: any) => {
  const { id } = record;
  return (
    <ShowButton
      to={{
        pathname: `/challenge/${id}`,
      }}
    />
  );
};

const SubscriptionsList: FC<any> = props => {
  const { record } = props;

  const generateData = useCallback(() => {
    const data: Record<string, any> = {};

    record?.subscriptionChallenges?.forEach((chl: any) => {
      data[chl.challengeId] = { id: chl.challengeId, name: chl.challenge.name };
    });

    return data;
  }, [record]);

  const [page, setPage] = useState(1);
  const perPage = 10;
  const sort = { field: 'id', order: 'ASC' };
  const data = generateData();
  const total = record?.subscriptionChallenges?.length;
  const ids = record?.subscriptionChallenges?.map(
    (chl: any) => chl.challengeId,
  );

  return (
    <Card {...props}>
      <Title title="Challenges" />
      <CardContent {...props}>
        {Object.keys(data).length ? (
          <ListContextProvider
            value={{
              data,
              ids,
              total,
              page,
              perPage,
              setPage,
              sort,
            }}
          >
            <Datagrid currentSort={sort}>
              <TextField source="name" />
              <ShowOverviewButton />
            </Datagrid>
          </ListContextProvider>
        ) : (
          <ListEmpty />
        )}
      </CardContent>
    </Card>
  );
};

export default SubscriptionsList;
