import * as React from 'react';
import { MenuItemLink } from 'react-admin';
import { MultiLevelMenu } from '@react-admin/ra-navigation';
import { useStyles } from './useStyles';
import PersonIcon from '@material-ui/icons/Person';
const Menu: React.FC = () => {
  const classes = useStyles();

  return (
    <MultiLevelMenu>
      <div className={classes.menu}>
        <MenuItemLink
          sidebarIsOpen={true}
          to="/exercise-library"
          primaryText="Exercises Library"
          leftIcon={<PersonIcon />}
        />
        <MenuItemLink
          sidebarIsOpen={true}
          to="/user"
          primaryText="Users"
          leftIcon={<PersonIcon />}
        />
        <MenuItemLink
          sidebarIsOpen={true}
          to="/challenge"
          primaryText="Challenges"
          leftIcon={<PersonIcon />}
        />
        <MenuItemLink
          sidebarIsOpen={true}
          to="/device"
          primaryText="Devices"
          leftIcon={<PersonIcon />}
        />
        <MenuItemLink
          sidebarIsOpen={true}
          to="/notification"
          primaryText="Notifications"
          leftIcon={<PersonIcon />}
        />
        <MenuItemLink
          sidebarIsOpen={true}
          to="/subscription"
          primaryText="Subscriptions"
          leftIcon={<PersonIcon />}
        />
        <MenuItemLink
          sidebarIsOpen={true}
          to="/settings"
          primaryText="App Settings"
          leftIcon={<PersonIcon />}
        />
        <MenuItemLink
          sidebarIsOpen={true}
          to="/user-subscription"
          primaryText="User Subscription"
          leftIcon={<PersonIcon />}
        />
      </div>
    </MultiLevelMenu>
  );
};

export default Menu;
