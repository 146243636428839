import { makeStyles } from '@material-ui/core/styles';

export const useBreadcrumbStyles = makeStyles({
  container: {
    display: 'flex',
    margin: '32px 0 16px',
    gap: 8,
    '& > p': {
      fontSize: '1.25rem',
      lineHeight: '20px',
      fontWeight: 700,
      '& > a': {
        color: 'black',
      },
    },
  },
});
