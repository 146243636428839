import { FC, useCallback, useEffect, useState } from 'react';
import { useFormState } from 'react-final-form';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import debounce from 'lodash.debounce';
import { useStyles } from './styles';

interface VideoPreviewProps {
  source: string;
}

type DebouncedFn = (str: string) => void;

const isValidURL = (str: string) => {
  try {
    const url = new URL(str);
    return url.protocol === 'http:' || url.protocol === 'https:';
  } catch (error) {
    return false;
  }
};

const VideoPreview: FC<VideoPreviewProps> = ({ source }) => {
  const classes = useStyles();
  const { values } = useFormState();
  const [src, setSrc] = useState(values[source]);

  const videoUrl = values[source];
  const isValid = isValidURL(src);

  const debounced = useCallback(
    debounce<DebouncedFn>(url => setSrc(url), 500),
    [],
  );

  useEffect(() => {
    debounced(videoUrl);
  }, [videoUrl]);

  return (
    <Box display="flex" flexDirection="column" marginBottom="24px">
      <Typography style={{ fontWeight: 'bold', fontSize: 24 }}>
        Video Preview
      </Typography>
      <Box className={classes.container}>
        {isValid ? (
          <iframe className={classes.frame} src={src} />
        ) : (
          <Typography className={classes.errorText}>
            This video is unavailable! Please check the URL and/or video
            availability and try again!
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default VideoPreview;
