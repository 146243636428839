import * as React from 'react';
import { Create } from 'react-admin';
import { validateChallengeCreation } from './challengeValidation';
import { useChallengeStyles } from './useChallengeStyles';
import { ChallengeForm } from '../components/challenges-edit-form';

export const ChallengeCreate = (props: any) => {
  const classes = useChallengeStyles();
  return (
    <Create {...props} className={classes.createBox}>
      <ChallengeForm
        validate={validateChallengeCreation}
        redirectOnSave="edit"
      />
    </Create>
  );
};
