import React, { Fragment } from 'react';
import { SimpleForm as SimpleFormRA, SimpleFormProps } from 'react-admin';
import ToolBar from 'components/Toolbar';

type Props = {
  hideToolbar?: boolean;
  customSaveButton?: React.ReactNode;
} & SimpleFormProps;

export const SimpleForm: React.FC<Props> = ({
  hideToolbar,
  children,
  ...props
}) => (
  <SimpleFormRA
    {...props}
    toolbar={hideToolbar ? <Fragment /> : <ToolBar {...props} />}
  >
    {children}
  </SimpleFormRA>
);
export default SimpleForm;
